import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';

import * as AdminInfoActionCreators from '../../store/actions/user';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useTranslation } from "react-i18next";

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function AccountOptions({ classes }) {

    const dispatch = useDispatch();
    const { changeEmail, changePassword, getCredentials } = bindActionCreators({ ...AdminInfoActionCreators }, dispatch);
    const user = useSelector((state) => state.user);

    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [currentMail, setCurrentMail] = useState(user.email);
    const [newMail, setNewMail] = useState("");

    const [oldPassword, setOldPassword] = useState("");
    const [newPasswordOne, setNewPasswordOne] = useState("");
    const [newPasswordTwo, setNewPasswordTwo] = useState("");

    const [passwordMessage, setPasswordMessage] = useState("");
    const [emailMessage, setEmailMessage] = useState("");

    useEffect(() => {
        setCurrentMail(user.email)
        setNewMail("")
    }, [user.email])

    useEffect(() => {
        if (user.loadingPassword == false) {
            setOldPassword('')
            setNewPasswordOne('')
            setNewPasswordTwo('')
        }
    }, [user.loadingPassword])

    const handleChangeEmail = () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(newMail)) {
            changeEmail(currentMail, newMail)
            getCredentials()
        }
        else {
            setEmailMessage('Your mail format is wrong.')
            setTimeout(function () {
                setEmailMessage('')
            }, 2000);
        }
    }
    const handleChangePassword = () => {
        if (newPasswordOne == newPasswordTwo)
            changePassword(oldPassword, newPasswordOne)
        else {
            setPasswordMessage('Your new passwords do not match')
            setTimeout(function () {
                setPasswordMessage('')
            }, 2000);
        }
    }

    return (
        <Grid container direction="row" style={{ height: "100%" }}>
            <Grid container direction="row" >
                <Grid container xs={12} className={classes.root}>
                    {
                        width > 700 &&
                        <Grid item xs={2} md={3} className={classes.container}>
                            <Typography variant="h5">{t('Name')}</Typography>
                        </Grid>
                    }
                    {
                        width > 700 && <Divider variant="vertical" className={classes.divider} />
                    }
                    <Grid item xs={9} md={8} className={classes.container}>
                        <Typography variant="h4" style={{ textAlign: "center", display: "inline-block", borderBottom: "2px solid black" }}>
                            {user.name + " " + user.surname}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.root}>
                    {
                        width > 700 &&
                        <Grid item xs={2} md={3} className={classes.container}>
                            <Typography variant="h5" >{t('EMail')}</Typography>
                        </Grid>
                    }
                    {
                        width > 700 && <Divider variant="vertical" className={classes.divider} />
                    }
                    <Grid item xs={9} md={8} className={classes.container} style={{ flexDirection: "column" }}>
                        <EmailRoundedIcon className={classes.icon} />
                        <TextField type="email" value={currentMail} onChange={e => setCurrentMail(e.target.value)} label={t('CurrentEmail')} variant="outlined" style={{ margin: "5px", width: "280px" }} />
                        <TextField type="email" value={newMail} onChange={e => setNewMail(e.target.value)} label={t('NewEmail')} variant="outlined" style={{ margin: "5px", width: "280px" }} />
                        {
                            emailMessage == '' ?
                                user.emailMessage != '' &&
                                <Typography variant="h5" >{user.emailMessage}</Typography>
                                :
                                <Typography variant="h5" >{emailMessage}</Typography>
                        }
                        {
                            !user.loadingEmail &&
                            <Button onClick={() => handleChangeEmail()} className={classes.button}>
                                {t('Change')}
                            </Button>
                        }
                    </Grid>

                </Grid>

                <Grid container xs={12} className={classes.root}>
                    {
                        width > 700 &&
                        <Grid item xs={2} md={3} className={classes.container}>
                            <Typography variant="h5">{t('Password')}</Typography>
                        </Grid>
                    }
                    {
                        width > 700 && <Divider variant="vertical" className={classes.divider} />
                    }
                    <Grid item xs={9} md={8} className={classes.container} style={{ flexDirection: "column" }}>
                        <LockOpenRoundedIcon className={classes.icon} />
                        <TextField label={t('CurrentPassword')} value={oldPassword} onChange={e => setOldPassword(e.target.value)} variant="outlined" type="password" required style={{ margin: "5px" }} />
                        <TextField label={t('NewPassword')} value={newPasswordOne} onChange={e => setNewPasswordOne(e.target.value)} variant="outlined" type="password" required style={{ margin: "5px" }} />
                        <TextField label={t('ReEnterPassword')} value={newPasswordTwo} onChange={e => setNewPasswordTwo(e.target.value)} variant="outlined" type="password" required style={{ margin: "5px" }} />
                        {
                            passwordMessage == '' ?
                                user.passwordMessage != '' &&
                                <Typography variant="h5" >{user.passwordMessage}</Typography>
                                :
                                <Typography variant="h5" >{passwordMessage}</Typography>
                        }
                        {
                            !user.loadingPassword &&
                            <Button onClick={() => handleChangePassword()} className={classes.button}>
                                {t('Change')}
                            </Button>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Grid >
    )
}

export default withStyles(styles)(AccountOptions)
