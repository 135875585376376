import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Video from '../../assets/AnimationVideo.mp4'
import MICTlogo from '../../assets/mict.png';
import s4a from '../../assets/s4a.jpg';
import GZZ from '../../assets/GGZ.png';
import uni from '../../assets/Uni.png';
import MSW from '../../assets/5MSW.png'
import withGutters from '../../HOC/withGutters/withGutters';
import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";

function StartingPage(props) {
    const { classes } = props
    const [width, setWidth] = useState(window.innerWidth);
    const { t } = useTranslation()

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const multiplyer = width < 1650 ? 0.9 : 0.45
    const videoWidth = width < 1650 ? Math.round(width * multiplyer) - 180 : Math.round(width * multiplyer) + 80;
    const videoHeight = Math.round(videoWidth / 1.6552);
    const containerWidth = Math.round(width * multiplyer) - 80;

    return (
        <div>
            <Grid container direction="row">
                <Grid container direction="column" >
                    <Paper variant="outlined" className={classes.container}>
                        <Typography variant="h3" className={classes.miniHeading} >
                            {t('Subtitle1')}
                        </Typography>
                        <Typography variant="h5" className={classes.miniText} >
                            {t('Paragraph1')}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Paper variant="outlined" style={{ height: (videoHeight + 40) + "px", width: (videoWidth + 40) + "px", backgroundColor: "rgba(29, 0, 245, 0.13)" }}>
                        <Grid container style={{ height: "100%", width: "100%", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <video height={videoHeight} width={videoWidth} controls autostart="true" autoPlay src={Video} type="video/mp4" />
                        </Grid>
                    </Paper>
                    <div className={classes.miniContainer} style={{ width: containerWidth + "px" }}>
                        <Paper variant="outlined" elevation={3} className={classes.paperContainer} >
                            <Typography variant="h4" className={classes.miniHeading}>
                                {t('Subtitle2')}
                            </Typography>
                            <Typography variant="h6" className={classes.miniText}>
                                {t('Paragraph2')}
                            </Typography>
                            <Typography variant="h6" className={classes.miniText}>
                                {t('Paragraph3')}
                            </Typography>
                        </Paper>
                        <Paper variant="outlined" elevation={3} className={classes.paperContainer}>
                            <Typography variant="h4" className={classes.miniHeading}>
                                {t('Subtitle3')}
                            </Typography>
                            <Typography variant="h6" className={classes.miniText}>
                                {t('Paragraph4')}
                            </Typography>
                        </Paper>
                    </div>
                </Grid>
                
                <Grid container direction="row" style={{ justifyContent: "center" }}>
                    <Grid container style={{ flexWrap: "wrap", justifyContent: "space-evenly", padding: "30px" }}>
                        <a href="https://www.5mict.com/">
                            <img src={MICTlogo} alt="MICTlogo" style={{height: "100px"}}></img>
                        </a>
                        <a href="https://5msoftware.nl/">
                            <img src={MSW} alt="GZZlogo" style={{height: "100px"}}></img>
                        </a>
                        {/*<a href="https://smart4all-project.eu/">
                            <img src={s4a} alt="s4alogo" style={{ width: "100px", height: "100px" }}></img>
                        </a>
                        <a href="https://www.ggzoostbrabant.nl/">
                            <img src={GZZ} alt="GZZlogo"></img>
                        </a>
                        <a href="https://www.tue.nl/en/">
                            <img src={uni} alt="unilogo" style={{ width: "160px", height: "100px" }}></img>
    </a>*/}
                    </Grid>
                    <Divider style={{ width: "80%", margin: "20px" }}></Divider>
                    <Grid container >
                        <p style={{ marginBottom: "30px", display: "block", width: "100%", color: "white", fontSize: "18px", textAlign: "center" }}>
                            Created by 5MICT. © 2021
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withGutters(withStyles(styles)(StartingPage))
