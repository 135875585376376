export const styles = theme => ({
    containerUnselected: {
        width: "100%",
        height: "60px",
        backgroundColor: "rgba(53, 162, 212)",
        marginTop: "5px",
        marginBottom: "5px",
        cursor: "pointer"
    },
    containerSelected: {
        width: "100%",
        height: "60px",
        backgroundColor: "rgb(63,72,204,0.8)",
        boxShadow: "0px 0px 3px rgb(63,72,204,1)",
        marginTop: "9px",
        marginBottom: "9px",
        cursor: "pointer"
    }
});