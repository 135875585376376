import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RefreshIcon from '@material-ui/icons/Refresh';

import PulloutScrollContainer from '../pullout-scroll-container/PulloutScrollContainer';
import PulloutCalendar from '../pullout-calendar/PulloutCalendar';

import * as ClientActionCreators from '../../store/actions/clients';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions'
import { formatDateLabelLong, formatDatesLabel } from '../../utils/functions/dateHelpers';

import { useTranslation } from "react-i18next";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function PulloutMenu(props) {
    const {
        classes,
        users,
        selectedTab,
        sideBarWidth,
        selectedPerson
    } = props

    const dispatch = useDispatch();
    const { resetNotiDataClients, setDataClient } = bindActionCreators({ ...ClientActionCreators }, dispatch);

    const { t } = useTranslation()
    const [userHover, setUserHover] = useState(false)
    const [datesHover, setDatesHover] = useState(false)
    const [userMenuOpened, setUserMenuOpened] = useState(false)
    const [datesMenuOpened, setDatesMenuOpened] = useState(false)
    const { width, height } = useWindowDimensions()
    const datesMenuWidth = (width < 750 || height < 630) ? 350 : ((width < 1100 || height < 910) ? 500 : 800)
    const usersMenuWidth = width < 600 ? 100 : 160

    const data = useSelector((state) => state.data)
    const clients = useSelector((state) => state.clients)

    const refreshCurrentUser = () => {
        const clientTMP = clients.selectedDataClient;
        resetNotiDataClients();
        setDataClient(clientTMP);
    }

    const containerUserName = userHover ? `${classes.iconContainer} ${classes.hover}` : `${classes.iconContainer} ${classes.noHover}`
    const containerDatesName = datesHover ? `${classes.iconContainer2} ${classes.hover}` : `${classes.iconContainer2} ${classes.noHover}`
    const containerRefreshName = `${classes.iconContainer3} ${classes.noHover}`
    if (selectedPerson == null && datesMenuOpened) {
        setDatesMenuOpened(false);
    }

    const formatDateLabel = () => {
        switch(selectedTab){
            case 'Meals': {
                return  data.selectedMealDate ?
                        formatDateLabelLong(data.selectedMealDate)
                        : 
                        t('PlsSelectDate')
            }
            case 'Statistics': {
                return  data.selectedStatDates && data.selectedStatDates.length > 0 ?
                        formatDatesLabel(data.selectedStatDates)
                        : 
                        t('PlsSelectDate')
            }
            case 'About patient': {
                return  data.selectedAboutDate ?
                        ''
                        : 
                        t('PlsSelectDate')
            }
            default: {
                return '';
            }
        }
    }

    return (
        <div>
            <div style={{ position: "fixed", width: `calc(100% - ${sideBarWidth}px)`, borderBottom: "2px solid black", alignItems: "center", alignContent: "center", justifyContent: "center", display: 'flex', flexDirection: "row", backgroundColor: "rgba(108, 123, 224, 0.65)" }}>

                <div style={{ width: !selectedPerson ? "350px" : "200px", justifyContent: "center", alignItems: "center", display: "inline-block", marginTop: "5px", marginBottom: "5px" }} >
                    <Typography variant="h6" style={{ color: "black", textAlign: "center" }}>
                        {
                            !selectedPerson ?
                                t('UserNotSelected')
                                : "ID: #" + selectedPerson.name
                        }
                    </Typography>
                </div>

                <div style={{ width: `100%`, justifyContent: "center", display: "inline-block", marginTop: "5px", marginBottom: "5px" }} >
                    <Typography variant="h6" style={{ color: "black", textAlign: "center" }} >
                        {
                            formatDateLabel()
                        }
                    </Typography>
                </div>

            </div>
            <div
                className={containerUserName}
                style={userMenuOpened ? { left: (usersMenuWidth + sideBarWidth - 40) + "px" } : { left: (sideBarWidth - 40) + "px" }}
                onMouseOver={() => setUserHover(true)}
                onMouseOut={() => setUserHover(false)}
                onClick={() => setUserMenuOpened(!userMenuOpened)}

            >
                <Grid container xs={12} direction="column" style={{ height: "100%", alignItems: "center", alignContent: "flex-end", justifyContent: "center" }}>
                    <Typography variant="h6" style={{ color: "white" }}> {t('Users')} </Typography>
                    <PersonIcon className={classes.icon} />
                </Grid>
            </div>
            {
                !data.loadingNewDates && selectedPerson &&
                <div
                    className={containerDatesName}
                    style={datesMenuOpened ? { left: (datesMenuWidth + sideBarWidth - 40) + "px" } : { left: (sideBarWidth - 40) + "px" }}
                    onMouseOver={() => setDatesHover(true)}
                    onMouseOut={() => setDatesHover(false)}
                    onClick={() => setDatesMenuOpened(!datesMenuOpened)}

                >
                    <Grid container xs={12} direction="column" style={{ height: "100%", alignItems: "center", alignContent: "flex-end", justifyContent: "center" }}>
                        <Typography variant="h6" style={{ color: "white" }}> {t('Dates')} </Typography>
                        <DateRangeIcon className={classes.icon} />
                    </Grid>
                </div>
            }
            {
                !data.loadingNewDates && selectedPerson &&
                <div
                    className={containerRefreshName}
                    style={{ left: (sideBarWidth - 40) + "px" }}
                    onClick={() => refreshCurrentUser()}

                >
                    <Grid container xs={12} direction="column" style={{ height: "100%", alignItems: "center", alignContent: "flex-end", justifyContent: "center" }}>
                        <Typography variant="h6" style={{ color: "white" }}> {t('Refresh')} </Typography>
                        <RefreshIcon className={classes.icon} />
                    </Grid>
                </div>
            }

            {
                userMenuOpened ?
                    <PulloutScrollContainer
                        users={users}
                        varient="info"
                        usersMenuWidth={usersMenuWidth}
                    />
                    : null
            }
            {
                datesMenuOpened ?
                    <PulloutCalendar
                        datesMenuWidth={datesMenuWidth}
                        selectedTab={selectedTab}
                        selectedPerson={selectedPerson}
                    />
                    : null
            }
        </div>
    )
}

export default withStyles(styles)(PulloutMenu)