import React from 'react'

import { useTranslation } from "react-i18next";

import { CartesianGrid, XAxis, YAxis, Tooltip, LineChart, Legend, Line } from 'recharts';
import BigChartContainer from '../big-chart-container/BigChartContainer';
import { convertMinutesIntoTime } from '../../utils/functions/dateHelpers';

import { CustomizedLabel, CustomizedAxisTick } from '../customized-chart-components/CustomizedChartComponents';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function StatisticsTimeLine(props) {
    const { classes, width, height, timeLineData, goBack } = props

    const { t } = useTranslation()

    const minwidth = width * 0.7;
    const minheight = height * 0.7;

    return (
        <BigChartContainer goBack={goBack} title={"Time line"}>
            <LineChart
                width={minwidth}
                height={minheight}
                data={timeLineData}
                margin={{ top: 15, right: 40, left: 10, bottom: 5 }}
            >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" padding={{ left: 30 }} />
                <YAxis
                    type='number'
                    tickCount={24}
                    name='String'
                    tick={<CustomizedAxisTick />}
                    domain={[0, 1440]}
                />
                <Tooltip formatter={(value) => convertMinutesIntoTime(value)} />
                <Legend />
                <Line type="bases" dataKey="breakfast" stroke="#82ca9d" label={<CustomizedLabel />} />
                <Line type="bases" dataKey="snack1" stroke="pink" label={<CustomizedLabel />} />
                <Line type="bases" dataKey="lunch" stroke="#a519d1" label={<CustomizedLabel />} />
                <Line type="bases" dataKey="snack2" stroke="red" label={<CustomizedLabel />} />
                <Line type="bases" dataKey="dinner" stroke="green" label={<CustomizedLabel />} />
                <Line type="bases" dataKey="snack3" stroke="black" label={<CustomizedLabel />} />
            </LineChart>
        </BigChartContainer>
    )
}

export default withStyles(styles)(StatisticsTimeLine)