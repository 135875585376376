export const styles = theme => ({
    title: {
        color: "inherit",
        "&:hover,&:focus": {
            color: "inherit",
            background: "transparent"
        },
    },
    language: {
        color: "white",
        fontSize: "24px"
    },
    logo: {
        color: "white",
        fontSize: "24px"
    },
    link: {
        color: "white",
        fontSize: "21px",
        textDecoration: 'none'
    },
});
