export const styles = theme => ({
    root: {
        height: "100%",
        justifyContent: "center",
        alignContent: "center"
    },
    container: {
        flexDirection: "column",
        height: "620px",
        width: "620px",
        backgroundColor: "white"
    },
    smallSegments: {
        height: "10%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    },
    bigSegment: {
        height: "80%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    },
    icon: {
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 40,
        margin: 5,
        fontSize: 80,
        padding: 5,
        marginTop: 15
    }

});