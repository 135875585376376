export const styles = theme => ({

    icons: {
        fontSize: "30px",
        marginLeft: 5,
        marginRight: 10,
        alignSelf: "center",
        color: "white"
    }

});