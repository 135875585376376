import React from 'react'

import { useTranslation } from "react-i18next";

import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import BigChartContainer from '../big-chart-container/BigChartContainer';
import { getColorForPie } from '../../utils/functions/statisticsFilters';

function StatisticsCaloriesBars(props) {
    const { height, width, goBack, skippedMealsData } = props;

    const { t } = useTranslation()

    const minwidth = width * 0.7;
    const minheight = height * 0.7;

    return (
        <BigChartContainer goBack={goBack} title={t('MealsSkipped')}>
            <PieChart width={minwidth} height={minheight}>
                <Pie data={skippedMealsData} dataKey="value" nameKey="name" cx="50%" cy="50%" fill="#82ca9d" label >
                    <Cell name={t('Breakfast')} key={`cell-0`} fill={getColorForPie(0)} fontSize="25px" />
                    <Cell name={t('1stSnack')} key={`cell-1`} fill={getColorForPie(1)} fontSize="25px" />
                    <Cell name={t('Lunch')} key={`cell-2`} fill={getColorForPie(2)} fontSize="25px" />
                    <Cell name={t('2ndSnack')} key={`cell-3`} fill={getColorForPie(3)} fontSize="25px" />
                    <Cell name={t('Dinner')} key={`cell-4`} fill={getColorForPie(4)} fontSize="25px" />
                    <Cell name={t('3rdSnack')} key={`cell-5`} fill={getColorForPie(5)} fontSize="25px" />
                </Pie>
                <Tooltip />
                <Legend verticalAlign="bottom" layout="horizontal" />
            </PieChart>
        </BigChartContainer>
    )
}

export default StatisticsCaloriesBars