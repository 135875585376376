import { combineReducers } from "redux";
import AuthReducer from './reducers/auth'
import UserReducer from './reducers/user'
import ClientsReducers from './reducers/clients'
import DataReducers from './reducers/data'

const reducers = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    clients: ClientsReducers,
    data: DataReducers
});

export default reducers