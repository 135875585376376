export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_DONE = "LOGOUT_DONE";

export const USER_INFO = "USER_INFO";
export const USER_EMAIL = "USER_EMAIL";
export const USER_PASSWORD_LOADING = "USER_PASSWORD_LOADING";
export const USER_PASSWORD_FAILED = "USER_PASSWORD_FAILED";
export const USER_PASSWORD_SUCCESS = "USER_PASSWORD_SUCCESS";
export const USER_EMAIL_LOADING = "USER_EMAIL_LOADING";
export const USER_EMAIL_FAILED = "USER_EMAIL_FAILED";
export const USER_RESET = "USER_RESET";

export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_SUCCESS = "CLIENTS_SUCCESS";
export const CLIENTS_FAILED = "CLIENTS_FAILED";
export const CLIENTS_PERSIST = "CLIENTS_PERSIST";
export const CLIENTS_NEW_CLIENT = "CLIENTS_NEW_CLIENT";
export const CLIENTS_NEW_CLIENT_LOADING = "CLIENTS_NEW_CLIENT_LOADING";
export const CLIENTS_NEW_CLIENT_FAILED = "CLIENTS_NEW_CLIENT_FAILED";
export const CLIENTS_NEW_ADMIN = "CLIENTS_NEW_ADMIN";
export const CLIENTS_NEW_ADMIN_LOADING = "CLIENTS_NEW_ADMIN_LOADING";
export const CLIENTS_NEW_ADMIN_FAILED = "CLIENTS_NEW_ADMIN_FAILED";
export const CLIENTS_SET_DATACLIENT = "CLIENTS_SET_DATACLIENT";
export const CLIENTS_SET_NOTICLIENT = "CLIENTS_SET_NOTICLIENT";
export const CLIENT_RESET = "CLIENT_RESET";

export const DATA_LOADING = "DATA_LOADING";
export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_FAILED = "DATA_FAILED";
export const DATA_PERSIST = "DATA_PERSIST";
export const DATA_UPDATE_MEAL_DATE = "DATA_UPDATE_MEAL_DATE";
export const DATA_UPDATE_ABOUT_DATE = "DATA_UPDATE_ABOUT_DATE";
export const DATA_UPDATE_STAT_DATES = "DATA_UPDATE_STAT_DATES";
export const DATA_CLEAR = "DATA_CLEAR";
export const DATA_UPDATE_CALENDAR_DATE = "DATA_UPDATE_CALENDAR_DATE";
export const DATA_DAILY_INTAKE = "DATA_DAILY_INTAKE";
export const DATA_DAILY_INTAKE_LOADING = "DATA_DAILY_INTAKE_LOADING";
export const DATA_DAILY_INTAKE_FINISH = "DATA_DAILY_INTAKE_FINISH";
