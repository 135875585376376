import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_DONE
} from "../actions.js";

import { initToken } from "../../utils/functions/storage.js";
import axios from '../../utils/axios/axios';

import i18next from '../../utils/i18n/i18n';

export const tryLogIn = (username, password) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('username', username)
        formData.append('password', password)
        dispatch(logInLoading())
        axios
            .getInstance()
            .post(
                "admin/login_check",
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then(async (response) => {
                const { code, data } = response.data;
                if (code == 200) {
                    const { token } = data;
                    await initToken(token);
                    dispatch(logInSuccesfull());
                }
                if (code == 401) {
                    dispatch(logInFailed())
                }
            })
            .catch(error => {
                dispatch(logInFailed(error))
                throw error;
            });
    };
};

export const logOut = () => {
    return (dispatch) => {
        axios
            .getInstance()
            .post("logout")
            .then(response => {
                if (response.data.code == 200)
                    dispatch(logOutDone())
            })
            .catch(error => {
                throw error;
            });
    };
};



const logOutDone = () => {
    return {
        type: LOGOUT_DONE,
        payload: ""
    }
}

const logInLoading = () => {
    return {
        type: LOGIN_LOADING,
        payload: i18next.t('Logging')
    }
}

const logInSuccesfull = () => {
    return {
        type: LOGIN_SUCCESS,
        payload: i18next.t('LogSuccess')
    }
}

const logInFailed = (error = "") => {
    return {
        type: LOGIN_FAILED,
        payload: {
            message: i18next.t('LogInvalid'),
            errorMessage: error
        }
    }
}