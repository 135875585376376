import React from 'react'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function PulloutScrollItem(props) {
    const { item, isSelected, clickHandler, classes } = props;

    const { width, height } = useWindowDimensions();

    return (
        <Paper
            elevation={isSelected ? 10 : 2}
            className={isSelected ? classes.containerSelected : classes.containerUnselected}
            onClick={() => clickHandler(item)}
        >
            <Grid container direction="row" xs={12} style={{ height: "100%", alignContent: "center", justifyContent: "center" }}>
                <Typography style={{ display: "inline-block", color: "rgba(255, 255, 255)", fontSize: width < 600 ? "15px" : "24px" }}>
                    {item.name}
                </Typography>
            </Grid>
        </Paper>
    )
}

export default withStyles(styles)(PulloutScrollItem)