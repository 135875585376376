export function createDatesForMonth(date, lowBorder) {
    const highBorder = new Date()

    const localeDate = new Date(date)
    const tiles = []

    localeDate.setDate(1);
    const startFillerTiles = localeDate.getDay();
    localeDate.setDate(0)

    if (startFillerTiles > 0) {
        localeDate.setDate(localeDate.getDate() - startFillerTiles)
        for (let i = 0; i < startFillerTiles; i++) {
            localeDate.setDate(localeDate.getDate() + 1)
            tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "previous" : "unused", date: new Date(localeDate) })
        }
    }

    localeDate.setDate(localeDate.getDate() + 1)
    localeDate.setMonth(localeDate.getMonth() + 1)
    localeDate.setDate(0)

    const mainTails = localeDate.getDate() + startFillerTiles
    localeDate.setDate(1)
    for (let i = startFillerTiles; i < mainTails; i++) {
        tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "current" : "unused", date: new Date(localeDate) })
        localeDate.setDate(localeDate.getDate() + 1)
    }

    const endFillTiles = 42
    localeDate.setMonth(localeDate.getMonth())
    localeDate.setDate(1)
    for (let i = mainTails; i < endFillTiles; i++) {
        tiles.push({ id: i, type: isInRange(localeDate, lowBorder, highBorder) ? "next" : "unused", date: new Date(localeDate) })
        localeDate.setDate(localeDate.getDate() + 1)
    }

    return tiles
}
export function equalDates(date1, date2) {
    if (date1 == null) return false
    if (date2 == null) return false
    if (
        date1.getDate() == date2.getDate() &&
        date1.getFullYear() == date2.getFullYear() &&
        date1.getMonth() == date2.getMonth()
    ) {
        return true
    } else {
        return false
    }
}

export function isInRange(date, lowBorder, highBorder) {

    if (date == null) return false
    if (lowBorder == null) return false
    if (highBorder == null) return false

    if (date.getTime() < lowBorder.getTime())
        return false
    if (highBorder.getTime() <= date.getTime())
        return false

    return true
}