import React from "react";
import Container from "@material-ui/core/Container";
import food from '../../assets/food.jpg';
import tech from '../../assets/digital-pattern.jpg';

function withGutters(Component) {
    return function () {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Container maxWidth="xl" style={{ backgroundColor: "#3f64dce9", height: "100%", minHeight: "100%", width: "100%", paddingTop: "90px", zIndex: 9999 }}>
                    <Component />
                </Container>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: "50vw",
                        bottom: "100vh",
                        height: "100%",
                        backgroundAttachment: "fixed",
                        zIndex: -2,
                        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0.90), rgba(255, 255, 255, 1)), url(${food})`,
                    }}
                />

                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: "50vw",
                        bottom: "100vh",
                        height: "100%",
                        backgroundAttachment: "fixed",
                        zIndex: -2,
                        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.90), rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0)), url(${tech})`,
                    }}
                />
            </div>

        )
    }
}

export default withGutters;