import React from 'react';

import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

import { useTranslation } from "react-i18next";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function EmotionStars(props) {
    const { classes, stars} = props
    const emptyStars = 5 - stars

    const { t } = useTranslation();

    const render = [];
    if(stars != 0)
    {
        for (let i = 0; i < stars; i++)
            render.push(<StarOutlinedIcon style={{ fontSize: "16" }}></StarOutlinedIcon>)
        for (let i = 0; i < emptyStars; i++)
            render.push(<StarBorderOutlinedIcon style={{ fontSize: "15" }}></StarBorderOutlinedIcon>)
    }

    return (
        <div style={{ padding: "0px", width: "100px",textAlign: "start", paddingLeft: "5px"}}>
                <div className={classes.starsContainer}>
                    {
                        render
                    }
                </div>
        </div>
    )
}

export default withStyles(styles)(EmotionStars);