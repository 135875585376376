import React from "react";

import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CssBaseline } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';

function withMaterial(Component) {
    return function () {
        const theme = createTheme({
            // palette: {
            //     primary: {
            //         light: "#f0f4c3",
            //         main: "#e6ee9c",
            //         dark: "#dce775"
            //     },
            //     secondary: {
            //         light: "#f0f4c3",
            //         main: "#e6ee9c",
            //         dark: "#dce775"
            //     },
            //     ternary: {
            //         light: "#f0f4c3",
            //         main: "#e6ee9c",
            //         dark: "#dce775"
            //     }
            // },
            scrollProperties: {
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                }
            }
        })
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Component />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        )
    }
}
export default withMaterial;