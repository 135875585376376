import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getBingesForDates, getCaloriesForDates, getSkippedMealsForDates, getTimeLineForDates } from '../../utils/functions/statisticsFilters'

import { useSelector } from 'react-redux';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import StatisticsSmallCharts from '../../components/statistics-small-charts/StatisticsSmallCharts';
import StatisticsBingeLineChart from '../../components/statistics-binge-line-chart/StatisticsBingeLineChart';
import StatisticsBingeBars from '../../components/statistics-binge-bars/StatisticsBingeBars';
import StatisticsCaloriesBars from '../../components/statistics-calories-bars/StatisticsCaloriesBars';
import StatisticsSkippedMeals from '../../components/statistics-skipped-meals-pie/StatisticsSkippedMealsPie';
import StatisticsTimeLine from '../../components/statistics-time-line/StatisticsTimeLine';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import './test.css'

import { useTranslation } from "react-i18next";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function Statistics(props) {
    const { classes, selectedPerson } = props;
    const { height, width } = useWindowDimensions()
    const { t } = useTranslation()

    const [bingesData, setBingesData] = useState([])
    const [timeLineData, setTimeLineData] = useState([])
    const [caloriesData, setCaloriesData] = useState([])
    const [skippedMealsData, setSkippedMealsData] = useState([])

    const data = useSelector((state) => state.data)

    const [selectedChart, setSelectedChart] = useState(0)
    function handleChartClick(id) {
        setSelectedChart(id)
    }

    useEffect(() => {
        setBingesData(getBingesForDates(data.selectedStatDates, data.data))
        setCaloriesData(getCaloriesForDates(data.selectedStatDates, data.data))
        setSkippedMealsData(getSkippedMealsForDates(data.selectedStatDates, data.data))
        setTimeLineData(getTimeLineForDates(data.selectedStatDates, data.data))
    }, [selectedPerson, data.selectedStatDates])

    let renderedChart;
    switch (selectedChart) {
        case 1:
            renderedChart =
                <StatisticsTimeLine
                    height={height}
                    width={width}
                    timeLineData={timeLineData}
                    goBack={() => handleChartClick(0)}
                />
            break;
        case 2:
            renderedChart =
                <StatisticsBingeBars
                    height={height}
                    width={width}
                    bingesData={bingesData}
                    goBack={() => handleChartClick(0)}
                />
            break;
        case 3:
            renderedChart =
                <StatisticsCaloriesBars
                    height={height}
                    width={width}
                    caloriesData={caloriesData}
                    goBack={() => handleChartClick(0)}
                />
            break;
        case 4:
            renderedChart =
                <StatisticsSkippedMeals
                    height={height}
                    width={width}
                    skippedMealsData={skippedMealsData}
                    goBack={() => handleChartClick(0)}
                />
            break;
        default:
            renderedChart =
                <StatisticsSmallCharts
                    width={width}
                    height={height}
                    caloriesData={caloriesData}
                    bingesData={bingesData}
                    timeLineData={timeLineData}
                    skippedMealsData={skippedMealsData}
                    handleChartPress={handleChartClick}
                />
    }

    return (
        <Grid container direction="row" className={classes.root} >
            {
                selectedPerson !== '' && data.selectedStatDates && data.selectedStatDates.length > 0 ?
                    renderedChart
                    :
                    <div>
                        {
                            selectedPerson && selectedPerson !== '' ? null :
                                <>
                                    <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "127px", fontSize: "50px" }} />
                                    <Typography variant="h5" style={{ position: "absolute", top: "137px", left: width > 1250 ? "370px" : "230px" }}>
                                        {t('Tip1')}
                                    </Typography>
                                </>

                        }
                        {
                            data.selectedStatDates && data.selectedStatDates.length > 0 ? null :
                                !data.loadingNewDates && selectedPerson &&
                                <>
                                    <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "198px", fontSize: "50px" }} />
                                    <Typography variant="h5" style={{ position: "absolute", top: "208px", left: width > 1250 ? "370px" : "230px" }}>
                                        {t('Tip2')}
                                    </Typography>
                                </>

                        }
                    </div>
            }
        </Grid>
    )
}

export default withStyles(styles)(Statistics)
