

export const styles = theme => ({
    root: {
        width: "100%",
        paddingTop: "44px",
        height: "100%",
        background: `linear-gradient(to top, rgba(157, 167, 233, 0.6),rgba(157, 167, 233, 0.75), rgba(157, 167, 233, 0.9), rgba(157, 167, 233, 1))`
    },
    headerContainer: {
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        width: "100%",
        paddingLeft: "55px"
    },
    cardsBig: {
        width: "200px",
        height: "140px",
        padding: "10px",
        margin: "10px",
        cursor: "pointer",
        borderColor: "black",
        alignItems: "space-evenly",
        justifyContent: "space-evenly"
    },
    cardsSmall: {
        width: "150px",
        height: "185px",
        padding: "10px",
        margin: "10px",
        cursor: "pointer",
        borderColor: "black"
    },
    cardsText: {
        textJustify: "center",
        textAlign: "center"
    },
    divider: {
        width: "99%",
        marginTop: "10px",
        marginBottom: "10px",
        alignSelf: "center",
        background: "black"
    },
    itemDivider: {
        width: "100%",
        background: "black",
        marginBottom: "5px",
        marginTop: "5px",
        alignSelf: "flex-end"
    },
    textContainer: {
        height: "200px",
        padding: "10px",
        backgroundColor: "transparent",
        boxShadow: "1px 2px 5px #0d25bf"
    },
    itemsContainer: {
        height: "420px",
        padding: "10px",
        backgroundColor: "transparent",
        boxShadow: "1px 2px 5px #0d25bf"
    },
    scrollProperties: {
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            borderTop: '1px solid rgb(100,100,100)',
            backgroundColor: 'rgba(108, 123, 224, 0.65)',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            borderTop: '1px solid rgb(100,100,100)',
            backgroundColor: '#3f51b5',
            outline: '1px solid slategrey'
        }
    },
    itemsScroll: {
        height: "310px",
        overflowY: "scroll",
        alignContent: "flex-start",
        ...theme.scrollProperties
    },
    emotionCardsContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
        padding: "5px",
        backgroundColor: "transparent",
        flexWrap: "wrap",
        boxShadow: "1px 2px 5px #0d25bf"
    },
    headerRow: {
        backgroundColor: "#3f51b5"
    },
    headerRowCell: {
        color: "white"
    },
    commitedRow: {
        backgroundColor: "rgb(255,255,255)"
    },
    uncommitedRow: {
        backgroundColor: "rgb(255, 194, 194)"
    },
    bingeRow: {
        backgroundColor: "rgb(255, 232, 194)"
    },
    footerRow: {
        backgroundColor: "rgba(108, 123, 224, 0.65)"
    },
    footerRowCell: {
        fontWeight: "bold",
        borderBottom: "none"
    },
    tableTitle: {
        height:50, 
        marginTop: '20px',
        fontSize: '20px',
        paddingTop: '10px',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    intakeButton:{
        backgroundColor: "#3f51b5",
        margin:5,
        paddingLeft:15,
        paddingRight:15,
        color: "white",
        borderRadius: 5,
        cursor: "pointer",
        
    },
    modal: {
        display: 'flex',
        justifyContent: "center",
        marginBottom: 10
    },
    loadingDailyIntake: {
        fontSize: 20,
    }
});