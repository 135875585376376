export const styles = theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        margin: "30px",
        paddingRight: "35px",
        paddingTop: "5px",
        paddingBottom: "5px",
        justifyItems: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    divider: {
        width: '200px',
        background: "black",
        marginTop: "5px",
        marginBottom: "10px"
    }
})