import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_DONE
} from '../actions.js';

const initialState = {
    authenticated: false,
    message: "",
    errorMessage: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return { ...state, message: action.payload, errorMessage: "" };

        case LOGIN_SUCCESS:
            return { ...state, message: action.payload.message, authenticated: true };

        case LOGIN_FAILED:
            return { ...state, message: action.payload.message, errorMessage: action.payload.errorMessage }

        case LOGOUT_DONE:
            return { ...state, authenticated: false, errorMessage: "", message: "" }

        default:
            return state;
    }
}