import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as ClientsActionCreators from '../../store/actions/clients';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from '../../utils/axios/axios';


function AdminPanel(props) {

    const { classes, users } = props

    useEffect(()=>{
        console.log(users)
    })

    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [adminMessage, setAdminMessage] = useState('');

    

    const { width } = useWindowDimensions();
    const { t } = useTranslation();

    const clients = useSelector((state) => state.clients);
    const dispatch = useDispatch();
    const { makeUser, makeAdmin, fetchClients } = bindActionCreators({ ...ClientsActionCreators }, dispatch);

    useEffect(() => {
        if (!clients.newAdminLoading && clients.newAdminCreated) {
            setUsername("");
            setName("");
            setSurname("");
            setEmail("");
        }
    }, [clients.newAdminLoading])

    const handleMakeClient = () => {
        makeUser()
    }
    const handleMakeAdmin = () => {
        if (name == '' || surname == '' || email == '' || username == '') {
            setAdminMessage('You need to fill out all the fields.');
            setTimeout(function () {
                setAdminMessage('');
            }, 2000);
            return
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            makeAdmin(name, surname, email, username);
        }
        else {
            setAdminMessage('Your mail format is wrong.');
            setTimeout(function () {
                setAdminMessage('');
            }, 2000);
        }
    }

    const [selectedIndex, setSelectedIndex] = useState(-1)

    const [newPassword, setNewPassword] = useState('')

    const [usersMessage, setUsersMessage] = useState('')

    const handleChangeUserPassword = (id,newpassword) => {
        
        if(newpassword === '')
        {
            setUsersMessage('You have not entered a new password.');
            setTimeout(function () {
                setUsersMessage('');
            }, 2000);
            return
        }
        setUsersMessage('Loading...');
        console.log("ID: ",id," PASSWORD: ",newpassword)
        const formData = new URLSearchParams();
        formData.append('password', newpassword)
        axios
            .getInstance()
            .patch(`/settings/change-user-password/${id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then(async (response) => {
                const { code } = response.data;
                if (code === 200) {
                    setUsersMessage('Successfuiily changed password');
                    fetchClients()
                    setTimeout(function () {
                        setUsersMessage('');
                    }, 2000);
                } 
                else
                {
                    setUsersMessage('Error');
                    setTimeout(function () {
                        setUsersMessage('');
                    }, 2000);
                }
            })
            .catch(error => {
                throw error;
            });
        setNewPassword("")
    }

    return (
        <Grid container direction="row" className={classes.root} >

            <Grid container xs={12} direction="row" className={classes.container}>
                <Grid item xs={12} md={5} className={classes.formContainer} style={width < 960 ? { marginTop: "20px", marginBottom: "20px" } : {}}>
                    <Typography variant="h4" style={{ textAlign: "center" }}>
                        {t('UserAccounts')}
                    </Typography>
                    <Typography varient="h6" style={{ textAlign: "center" }}>
                        {t('CreateNewUser')}
                    </Typography>
                    <Button onClick={() => handleMakeClient()} className={classes.button} >
                        {t('Create')}
                    </Button>
                    <Typography varient="h6" style={{ textAlign: "center", marginTop:50}}>
                        {t('PasswordRequest')}
                    </Typography>
                    <div style={{width: "100%", textAlign: "center"}} onClick={()=>{setSelectedIndex(-1)}}>
                        {
                            users.clientsData &&
                            users.clientsData.map((user, index)=>
                            <>
                            {
                                user.is_request_sent &&
                            
                            <div style={{fontSize: 18, padding:10, textAlign: "center", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                            {user.name}
                            {
                                selectedIndex === index ?
                                <>
                                    <TextField
                                    onChange={(e) => { setNewPassword(e.target.value) }}
                                    value={newPassword}
                                    type="text"
                                    label={t('New password')}
                                    variant="outlined"
                                    style={{ margin: "5px", width: "70%", maxWidth: "300px" }}
                                    inputProps={{ maxLength: 26 }}
                                    onClick={(e)=>{e.stopPropagation()}}
                                />
                                <button style={{fontSize: 18, padding:10, maxWidth: 150, textAlign: "center", border: "2px solid black", borderRadius: 7, backgroundColor: "rgb(191,197,239)", fontSize: 14}} onClick={(e)=>{handleChangeUserPassword(user.id, newPassword);e.stopPropagation()}}>SAVE</button>
                                </>
                                :
                                <>
                                <button style={{fontSize: 18, padding:10, maxWidth: 150, textAlign: "center", border: "2px solid black", borderRadius: 7, backgroundColor: "rgb(191,197,239)", fontSize: 14}} onClick={(e)=>{setSelectedIndex(index);setNewPassword("");e.stopPropagation()}}>CHANGE</button>
                                </>
                            }
                            </div>
                            }
                            </>
                            )
                        }
                        {
                            usersMessage != '' &&
                            <Typography variant="h5" >{usersMessage}</Typography>
                        }
                    </div>
                </Grid>
                {width > 960 && <Divider orientation="vertical" className={classes.divider} />}
                <Grid item xs={12} md={5} className={classes.feedBackContainer} style={width < 960 ? { marginTop: "20px", marginBottom: "20px" } : {}}>
                    {
                        clients.newClientCreated ?
                            <div className={classes.feedBackWrapper} >
                                <Typography variant="h4" style={{ marginBottom: "10px" }} >{t('NewUserCreated')}</Typography>
                                <Typography variant="h6" style={{ marginBottom: "10px", textAlign: 'center' }} >{t('UserMessage1')}</Typography>
                                <Typography variant="h6">{t('Username')}: {clients.newClientCreated.username}</Typography>
                                <Typography variant="h6">{t('Password')}: {clients.newClientCreated.password} </Typography>
                            </div>
                            :
                            clients.newClientLoading &&
                            <div className={classes.feedBackWrapperText}>
                                <Typography variant="h6" >{clients.newClientMessage}</Typography>
                            </div>
                    }

                </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={classes.container}>
                <Grid item xs={12} md={5} className={classes.formContainer} style={width < 960 ? { marginTop: "20px", marginBottom: "20px" } : {}}>

                    <Typography variant="h4" style={{ textAlign: "center" }}>
                        {t('AdminAccounts')}
                    </Typography>

                    <Typography varient="h6" style={{ textAlign: "center", marginBottom: "10px" }}>
                        {t('CreateNewAdmin')}
                    </Typography>

                    <div className={classes.formInfoWrapper}>
                        <Typography style={{ textAlign: "center", width: "40%" }}>
                            {t('EMail')}
                        </Typography>
                        <TextField
                            onChange={(e) => { setEmail(e.target.value) }}
                            value={email}
                            type="email"
                            label="example@mail.com"
                            variant="outlined"
                            style={{ margin: "5px", width: "70%", maxWidth: "300px" }}
                            inputProps={{ maxLength: 36 }}
                        />
                    </div>

                    <div className={classes.formInfoWrapper}>
                        <Typography style={{ textAlign: "center", width: "40%" }}>
                            {t('Name')}:
                        </Typography>
                        <TextField
                            onChange={(e) => { setName(e.target.value) }}
                            value={name}
                            type="text"
                            label={t('Name')}
                            variant="outlined"
                            style={{ margin: "5px", width: "70%", maxWidth: "300px" }}
                            inputProps={{ maxLength: 26 }}
                        />
                    </div>

                    <div className={classes.formInfoWrapper}>

                        <Typography style={{ textAlign: "center", width: "40%" }}>
                            {t('Surname')}:
                        </Typography>
                        <TextField
                            onChange={(e) => { setSurname(e.target.value) }}
                            value={surname}
                            type="text"
                            label={t('Surname')}
                            variant="outlined"
                            style={{ margin: "5px", width: "70%", maxWidth: "300px" }}
                            inputProps={{ maxLength: 26 }}
                        />
                    </div>

                    <div className={classes.formInfoWrapper}>

                        <Typography style={{ textAlign: "center", width: "40%" }}>
                            {t('Username')}
                        </Typography>
                        <TextField
                            onChange={(e) => { setUsername(e.target.value) }}
                            value={username}
                            type="text"
                            label={t('Username')}
                            variant="outlined"
                            style={{ margin: "5px", width: "70%", maxWidth: "300px" }}
                            inputProps={{ maxLength: 16 }}
                        />
                    </div>

                    {
                        adminMessage != '' &&
                        <Typography variant="h5" >{adminMessage}</Typography>
                    }

                    <Button onClick={() => handleMakeAdmin()} className={classes.button} style={{ marginTop: "10px" }}>
                        {t('Create')}
                    </Button>

                </Grid>
                {width > 960 && <Divider orientation="vertical" className={classes.divider} />}
                <Grid item xs={12} md={5} className={classes.feedBackContainer} style={width < 960 ? { marginTop: "20px", marginBottom: "20px" } : {}}>
                    {
                        clients.newAdminCreated ?
                            <div className={classes.feedBackWrapper}>
                                <Typography variant="h4" style={{ marginBottom: "10px" }} >{t('NewAdminCreated')}</Typography>
                                <Typography variant="h6" style={{ marginBottom: "20px", textAlign: 'center' }} >{t('AdminMessage1')}</Typography>
                                <Typography variant="h5" style={{ marginBottom: "10px" }} >{clients.newAdminCreated.name + " " + clients.newAdminCreated.surname} </Typography>
                                <Typography variant="h6" >{t('EMail')}: {clients.newAdminCreated.email} </Typography>
                                <Typography variant="h6" >{t('Username')}: {clients.newAdminCreated.username} </Typography>
                                <Typography variant="h6" >{t('Password')}: {clients.newAdminCreated.password} </Typography>
                            </div>
                            :
                            clients.newAdminMessage !== "" &&
                            <div className={classes.feedBackWrapperText}>
                                <Typography variant="h6" >{clients.newAdminMessage}</Typography>
                            </div>
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export default withStyles(styles)(AdminPanel)
