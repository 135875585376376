import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';

import FastfoodIcon from '@material-ui/icons/Fastfood';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotificationsSharpIcon from '@material-ui/icons/NotificationsSharp';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';

import Meals from '../meals/Meals';
import Statistics from '../statistics/Statistics';
import AboutPatient from '../about-patient/AboutPatient';
import AccountOptions from '../account-options/AccountOptions';
import AdminPanel from '../admin-panel/AdminPanel';
import Notifications from '../notifications/Notification';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import SideBarHeading from '../../components/side-bar-heading/SideBarHeading';
import SideBarItem from '../../components/side-bar-item/SideBarItem';
import PulloutMenu from '../../components/pullout-buttons/PulloutMenu';

import * as ClientsActionCreators from '../../store/actions/clients'
import * as UserActionCreators from '../../store/actions/user';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

import food from '../../assets/food.jpg'

function Home({ classes }) {

    const [selectedItem, setSelectedItem] = useState('About patient');

    const clients = useSelector((state) => state.clients)
    const dispatch = useDispatch();
    const { fetchClients, getCredentials } = bindActionCreators({ ...ClientsActionCreators, ...UserActionCreators }, dispatch)

    const { width, height } = useWindowDimensions();

    useEffect(() => {
        fetchClients();
        getCredentials();
    }, []);

    const sideBarWidth = width < 1250 ? 80 : 250
    function handleItemPressed(name) {
        setSelectedItem(name)
    }

    let rendererComponent = null;
    switch (selectedItem) {
        case 'About patient': {
            rendererComponent =
                <>
                    <PulloutMenu
                        users={clients.clientsData}
                        sideBarWidth={sideBarWidth}
                        selectedTab={selectedItem}
                        selectedPerson={clients.selectedDataClient}
                    />
                    <AboutPatient
                        sideBarWidth={sideBarWidth}
                        selectedPerson={clients.selectedDataClient}
                    />
                </>
            break;
        }
        case 'Statistics': {
            rendererComponent =
                <>
                    <PulloutMenu
                        users={clients.clientsData}
                        sideBarWidth={sideBarWidth}
                        selectedTab={selectedItem}
                        selectedPerson={clients.selectedDataClient}
                    />
                    <Statistics
                        selectedPerson={clients.selectedDataClient}
                    />
                </>
            break;
        }
        case 'Account options': {
            rendererComponent = <AccountOptions />
            break;
        }
        case 'Admin panel': {
            rendererComponent = <AdminPanel users={clients}/>
            break;
        }
        case 'Notifications': {
            rendererComponent =
                <Notifications
                    users={clients.clientsNoti}
                />
            break;
        }
        default: {
            rendererComponent = <>
                <PulloutMenu
                    users={clients.clientsData}
                    sideBarWidth={sideBarWidth}
                    selectedTab={selectedItem}
                    selectedPerson={clients.selectedDataClient}
                />
                <Meals
                    sideBarWidth={sideBarWidth}
                    selectedPerson={clients.selectedDataClient}
                />
            </>
        }
    }

    return (
        <div style={{ paddingTop: "62px", width: "100%", height: "100%" }}>
            <div style={{ flex: 1, height: "100%", width: sideBarWidth + "px", padding: 5, position: "fixed", zIndex: 5 }}>
                <Grid container direction="row" style={{ padding: 10, height: height < 440 ? `55px` : `70px` }} >
                    {
                        height > 370 &&
                        <SideBarHeading heading="Info" />
                    }
                    <SideBarItem name="About patient" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <PersonIcon className={classes.icons} />
                    </SideBarItem>
                    <SideBarItem name="Meals" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <FastfoodIcon className={classes.icons} />
                    </SideBarItem>
                    <SideBarItem name="Statistics" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <LibraryBooksIcon className={classes.icons} />
                    </SideBarItem>
                    {
                        height > 370 &&
                        <SideBarHeading heading={sideBarWidth == 250 ? "Account" : "Acc"} />
                    }
                    <SideBarItem name="Account options" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <AccountBoxIcon className={classes.icons} />
                    </SideBarItem>
                    <SideBarItem name="Admin panel" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <SupervisorAccountOutlinedIcon className={classes.icons} />
                    </SideBarItem>
                    <SideBarItem name="Notifications" pressHandler={handleItemPressed} selectedItem={selectedItem}>
                        <NotificationsSharpIcon className={classes.icons} />
                    </SideBarItem>
                </Grid>
                <div
                    style={{
                        backgroundImage: `url(${food})`,
                        position: "absolute",
                        top: 0,
                        filter: `brightness(20%)`,
                        left: 0,
                        zIndex: -1,
                        width: sideBarWidth + "px",
                        height: "100%",
                    }}
                />
            </div>
            <div style={{ height: `100%`, marginLeft: sideBarWidth + "px", zIndex: -1 }}>
                {
                    rendererComponent
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(Home)
