import React from 'react'

import { useTranslation } from "react-i18next";

import { CartesianGrid, XAxis, YAxis, BarChart, Tooltip, Bar } from 'recharts';
import BigChartContainer from '../big-chart-container/BigChartContainer';

function StatisticsCaloriesBars(props) {

    const { height, width, goBack, caloriesData } = props;

    const { t } = useTranslation()

    const minwidth = width * 0.7;
    const minheight = height * 0.7;

    return (
        <BigChartContainer goBack={goBack} title={t('Calories')}>
            <BarChart width={minwidth} height={minheight} data={caloriesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="calories" fill="#8884d8" maxBarSize={230} />
            </BarChart>
        </BigChartContainer>
    )
}

export default StatisticsCaloriesBars