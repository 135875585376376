const emptyMeal = { id: null, favourite: false, meal_type: 1, time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
const emptyDailyMeals =
    [
        { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
        { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
        { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
        { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
        { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
        { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
    ]

const emptyMonthlyMeals =
    [
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ],
        [
            { favourite: false, meal_type: "breakfast", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "lunch", time: "", is_binge: false, feeling: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "dinner", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] },
            { favourite: false, meal_type: "snack", time: "", is_binge: false, feelings: "", thoughts: "", emotions: [], meal_items: [], recipe: [] }
        ]
    ]

export { emptyDailyMeals, emptyMeal, emptyMonthlyMeals }