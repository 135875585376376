export const initToken = (
    token,
) => {
    try {
        localStorage.setItem(
            "token",
            JSON.stringify({
                token: token
            })
        );
    } catch (error) {
    }
};

export const readToken = () => {
    try {
        return JSON.parse(localStorage.getItem("token")) || undefined;
    } catch (error) {
    }
};

export const removeToken = () => {
    try {
        localStorage.removeItem("token");
    } catch (error) {
    }
};