export const styles = theme => ({
    root: {
        height: '100%',
        background: `linear-gradient(to right, rgba(157, 167, 233, 0.4),rgba(157, 167, 233, 0.75), rgba(157, 167, 233, 0.9), rgba(157, 167, 233, 1))`
    },
    container: {
        justifyContent: "space-evenly",
        alignContent: "center"
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    feedBackContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    feedBackWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        border: "1px solid rgba(0,0,0,0.25)",
        padding: "10px"
    },
    feedBackWrapperText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px"
    },
    formInfoWrapper: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%"
    },
    divider: {
        backgroundColor: "black",
        height: "70%"
    },
    button: {
        border: "2px solid black",
        borderRadius: "10px"
    }
});