import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function BigChartContainer(props) {
    const { classes, title, goBack } = props
    return (
        <Grid container direction="column" className={classes.container}>
            <CloseIcon onClick={() => goBack()} className={classes.exitButton} />
            <Typography variant="h4" className={classes.text}>{title}</Typography>
            <Paper variant="elevation" elevation={20} style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
                {
                    props.children
                }
            </Paper>
        </Grid >
    )
}

export default withStyles(styles)(BigChartContainer)
