import axiosImport from "axios"
import { readToken } from "../functions/storage";

export const backendRoot = "https://www.nutrition-ai.nl/api";


export default class axios {
    static instance = null;
    static cancelToken = axiosImport.CancelToken.source();
    static cancel() {
        axios.cancelToken.cancel('request canceled')
        axios.cancelToken = axiosImport.CancelToken.source();
    }
    static getInstance() {
        if (axios.instance == null) {
            axios.instance = axiosImport.create({
                baseURL: backendRoot
            });

            axios.instance.interceptors.request.use(
                async config => {
                    const token = await readToken();
                    if (token) {
                        config.headers["Authorization"] = token['token'];
                    }
                    return config;
                },
                error => {
                    Promise.reject(error);
                }
            );

            // let authTokenRequest;

            // const getAuthToken = () => {
            //     if (!authTokenRequest) {
            //         authTokenRequest = new Promise(function (resolve, reject) {
            //             getRefreshToken().then(refreshToken => {
            //                 axios.instance
            //                     .post(
            //                         loginRoute,
            //                         {
            //                             refresh_token: refreshToken,
            //                             grant_type: "refresh_token",
            //                             client_id: client_id,
            //                             client_secret: client_secret
            //                         },
            //                         {"Content-Type": "application/x-www-form-urlencoded"}
            //                     )
            //                     .then(response => {
            //                         storeTokens("Bearer " + response.data.access_token, response.data.refresh_token).then(res => {
            //                                 return resolve("token refreshed");
            //                             }
            //                         ).catch(err => {
            //                             return reject(err);
            //                         })

            //                     })
            //                     .catch(error => {
            //                         return reject(error)
            //                     });
            //             }).catch(error => {
            //                 return reject(error)
            //             });
            //         });
            //         authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
            //     }

            //     return authTokenRequest;
            // };

            // function resetAuthTokenRequest() {
            //     authTokenRequest = null;
            // }

            // axios.instance.interceptors.response.use(
            //     response => {
            //         return response;
            //     },
            //     function (error) {
            //         const originalRequest = error.config;
            //         if (!error.response) {
            //             return Promise.reject({response: {data: {error_description: "No network connection!"}}});
            //         } else if (error.response.status === 401) {
            //             return getAuthToken().then(response => {
            //                 originalRequest._retry = true;
            //                 return axios.instance(originalRequest);
            //             }).catch(error => {
            //                 NavigationService.navigate("Login");
            //             });
            //         } else {
            //             return Promise.reject(error);
            //         }
            //     }
            // );
        }

        return axios.instance;
    }
}
