import React, { useState } from 'react'

import { CartesianGrid, XAxis, YAxis, BarChart, Legend, Bar, Pie, PieChart, Cell, Line, LineChart } from 'recharts';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CustomizedLabel, CustomizedAxisTick } from '../customized-chart-components/CustomizedChartComponents';

import { useTranslation } from "react-i18next";

import { convertMinutesIntoTime } from '../../utils/functions/dateHelpers';

import { getColorForPie } from '../../utils/functions/statisticsFilters';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function StatisticsSmallCharts(props) {
    const { classes, width, height, caloriesData, bingesData, skippedMealsData, timeLineData, handleChartPress } = props

    const [hoveredItem, setHoveredItem] = useState(-1)
    function handlePointerEnter(id) {
        setHoveredItem(id)
    }
    function handlePointerLeave() {
        setHoveredItem(-1)
    }

    const { t } = useTranslation()

    let minwidth = (width - 460) / 2 - 50
    let minheight = (height - 260) / 2 - 50
    minwidth = minwidth < 400 ? 400 : minwidth
    minheight = minheight < 300 ? 300 : minheight

    return (
        <>
            <Grid container xs={width < 1000 ? 12 : 6} style={width < 1000 ? { justifyContent: "center", alignContent: "center" } : { justifyContent: "flex-end", alignContent: "flex-end" }}>

                <Paper
                    variant={hoveredItem == 1 ? "elevation" : "outlined"}
                    elevation={20}
                    style={{
                        minWidth: (minwidth + 20) + "px",
                        minHeight: (minheight + 20) + "px",
                    }}
                    className={classes.container}
                    onPointerEnter={() => handlePointerEnter(1)}
                    onPointerLeave={() => handlePointerLeave()}
                    onClick={() => handleChartPress(1)}
                >
                    <Typography>{t('TimeLine')}</Typography>
                    <Divider className={classes.divider} />
                    <LineChart
                        width={minwidth}
                        height={minheight}
                        data={timeLineData}
                        margin={{ top: 15, right: 40, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="date" padding={{ left: 30 }} />
                        <YAxis
                            type='number'
                            tickCount={12}
                            name='String'
                            tick={<CustomizedAxisTick />}
                            domain={[0, 1440]}
                        />
                        <Line type="bases" dataKey="breakfast" stroke="#82ca9d" label={<CustomizedLabel />} />
                        <Line type="bases" dataKey="snack1" stroke="pink" label={<CustomizedLabel />} />
                        <Line type="bases" dataKey="lunch" stroke="#a519d1" label={<CustomizedLabel />} />
                        <Line type="bases" dataKey="snack2" stroke="red" label={<CustomizedLabel />} />
                        <Line type="bases" dataKey="dinner" stroke="green" label={<CustomizedLabel />} />
                        <Line type="bases" dataKey="snack3" stroke="black" label={<CustomizedLabel />} />
                    </LineChart>
                </Paper>

            </Grid>

            <Grid container xs={width < 1000 ? 12 : 6} style={width < 1000 ? { justifyContent: "center", alignContent: "center" } : { justifyContent: "flex-start", alignContent: "flex-end" }}>

                <Paper
                    variant={hoveredItem == 2 ? "elevation" : "outlined"}
                    elevation={20}
                    style={{
                        minWidth: (minwidth + 20) + "px",
                        minHeight: (minheight + 20) + "px",
                    }}
                    className={classes.container}
                    onPointerEnter={() => handlePointerEnter(2)}
                    onPointerLeave={() => handlePointerLeave()}
                    onClick={() => handleChartPress(2)}
                >
                    <Typography>{t('Binges')}</Typography>
                    <Divider className={classes.divider} />
                    <BarChart width={minwidth} height={minheight} data={bingesData} cursor="pointer">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="v" />
                        <YAxis />
                        <Bar dataKey="binges" fill="#8884d8" maxBarSize={130} />
                    </BarChart>
                </Paper>

            </Grid>

            <Grid container xs={width < 1000 ? 12 : 6} style={width < 1000 ? { justifyContent: "center", alignContent: "center" } : { justifyContent: "flex-end", alignContent: "flex-start" }}>

                <Paper
                    variant={hoveredItem == 3 ? "elevation" : "outlined"}
                    elevation={20}
                    style={{
                        minWidth: (minwidth + 20) + "px",
                        minHeight: (minheight + 20) + "px",
                    }}
                    className={classes.container}
                    onPointerEnter={() => handlePointerEnter(3)}
                    onPointerLeave={() => handlePointerLeave()}
                    onClick={() => handleChartPress(3)}
                >
                    <Typography>{t('Calories')}</Typography>
                    <Divider className={classes.divider} />
                    <BarChart width={minwidth} height={minheight} data={caloriesData} cursor="pointer">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Bar dataKey="calories" fill="#8884d8" maxBarSize={130} />
                    </BarChart>
                </Paper>

            </Grid>

            <Grid container xs={width < 1000 ? 12 : 6} style={width < 1000 ? { justifyContent: "center", alignContent: "center" } : { justifyContent: "flex-start", alignContent: "flex-start" }}>

                <Paper
                    variant={hoveredItem == 4 ? "elevation" : "outlined"}
                    elevation={20}
                    style={{
                        minWidth: (minwidth + 40) + "px",
                        minHeight: (minheight + 20) + "px",
                        padding: "5px"
                    }}
                    className={classes.container}
                    onPointerEnter={() => handlePointerEnter(4)}
                    onPointerLeave={() => handlePointerLeave()}
                    onClick={() => handleChartPress(4)}
                >
                    <Typography>{t('MealsSkipped')}</Typography>
                    <Divider className={classes.divider} />
                    <PieChart width={minwidth} height={minheight} cursor="pointer">
                        <Pie data={skippedMealsData} dataKey="value" nameKey="name" cx="50%" cy="50%" fill="#82ca9d" label >
                            <Cell name={t('Breakfast')} key={`cell-0`} fill={getColorForPie(0)} fontSize="16px" />
                            <Cell name={t('1stSnack')} key={`cell-1`} fill={getColorForPie(1)} fontSize="16px" />
                            <Cell name={t('Lunch')} key={`cell-2`} fill={getColorForPie(2)} fontSize="16px" />
                            <Cell name={t('2ndSnack')} key={`cell-3`} fill={getColorForPie(3)} fontSize="16px" />
                            <Cell name={t('Dinner')} key={`cell-4`} fill={getColorForPie(4)} fontSize="16px" />
                            <Cell name={t('3rdSnack')} key={`cell-5`} fill={getColorForPie(5)} fontSize="16px" />
                        </Pie>
                        <Legend verticalAlign="bottom" layout="horizontal" />
                    </PieChart>
                </Paper>

            </Grid>
        </>
    )
}

export default withStyles(styles)(StatisticsSmallCharts)