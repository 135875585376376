import React from 'react';

import PulloutScrollItem from '../pullout-scroll-item/PulloutScrollItem';

import * as ClientsActionCreators from '../../store/actions/clients';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function PulloutScrollContainer(props) {
    const { usersMenuWidth, classes, users, varient } = props

    const clients = useSelector((state) => state.clients)
    const dispatch = useDispatch()
    const { setDataClient, setNotiClient } = bindActionCreators({ ...ClientsActionCreators }, dispatch)

    return (
        <div
            style={{ width: usersMenuWidth + "px", zIndex: "999" }}
            className={classes.scrollProperties}
        >
            {
                users.map((item, index) => {
                    return <PulloutScrollItem key={index} item={item} isSelected={varient == "info" ? clients.selectedDataClient == item : clients.selectedNotiClient == item} clickHandler={varient == "info" ? setDataClient : setNotiClient} />
                })
            }
        </div>
    )
}

export default withStyles(styles)(PulloutScrollContainer)