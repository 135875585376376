import React from 'react';

import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router-dom';
import * as AuthActionCreators from '../../store/actions/auth';
import * as DataActionCreators from '../../store/actions/data';
import * as ClientActionCreators from '../../store/actions/clients';
import * as UserActionCreators from '../../store/actions/user';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useTranslation } from "react-i18next";

import manual from "../../assets/Toni-AI web manual.docx"

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function Navbar(props) {
    const { classes } = props;
    const history = useHistory();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const {
        logOut,
        clearData,
        resetCurrentClient,
        resetUser,
        resetNotiDataClients
    } = bindActionCreators({ ...AuthActionCreators, ...DataActionCreators, ...ClientActionCreators, ...UserActionCreators }, dispatch);

    const { t, i18n } = useTranslation();

    const { width } = useWindowDimensions()

    const handleLogOut = () => {
        clearData();
        resetCurrentClient();
        resetUser();
        logOut();
    }
    const handleLogoClick = () => {
        !auth.authenticated && history.push("/starting-page");
    }
    const handleLogIn = () => {
        history.push("/login");
    }

    const handleChangeLanguage = () => {
        if (i18n.language === 'en') {
            i18n.changeLanguage('nl');
            resetNotiDataClients();
            return
        }
        i18n.changeLanguage('en');
        resetNotiDataClients();
    }
    return (
        <React.Fragment>
            <Grid container direction="row" style={{ height: "100vh" }} >
                <AppBar position="fixed">
                    <Toolbar style={{ height: "64px" }}>
                        <Grid container direction="row" style={{ alignItems: 'center', alignContent: 'center', justifyContent: width > 620 ? "space-between" : "flex-end" }}>

                            {
                                width > 620 &&
                                <div className={classes.title} style={{cursor: "pointer"}} disableElevation variant="contained" color="primary" onClick={() => handleLogoClick()}>
                                    <Typography className={classes.logo}>FitSprite Nutrition</Typography>
                                </div>
                            }

                            <div style={{ alignContent: "center", alignItems: "center", flexDirection: 'row' }}>
                                {
                                    auth.authenticated &&
                                    <Button style={{ marginRight: "15px", height: "25px" }}>
                                        <a href={manual} download="Toni-AI web manual.docx" className={classes.link}>
                                            Download manual
                                        </a>
                                    </Button>
                                }
                                <Button variant="text" onClick={handleChangeLanguage} style={{ marginRight: "15px", height: "25px" }}>
                                    <Typography className={classes.language}>{i18n.language == 'en' ? 'NL' : 'EN'}</Typography>
                                </Button>
                                {
                                    auth.authenticated ?
                                        <Button variant="contained" color="secondary" style={{ height: "25px" }} onClick={() => handleLogOut()} >
                                            {t('LogOut')}
                                        </Button>
                                        :
                                        <Button variant="contained" color="secondary" style={{ height: "25px" }} onClick={() => handleLogIn()} >
                                            {t('LogIn')}
                                        </Button>
                                }
                            </div>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Grid item xs={12} height="100%">
                    {
                        props.children
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default withStyles(styles)(Navbar)
