import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './loadingStyle.css'

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { createDatesForMonth, equalDates } from '../../utils/functions/calendarHelper';

import { formatDateLabelShort, isMonthInFuture, isMonthInPast } from '../../utils/functions/dateHelpers'

import * as DataActionCreators from '../../store/actions/data'

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function PulloutCalendar(props) {
    const {
        classes,
        datesMenuWidth,
        selectedTab,
        selectedPerson
    } = props

    const [currantTiles, setCurrantTiles] = useState([])
    const [hoverID, setHoverID] = useState(-1)

    const { t } = useTranslation()
    const data = useSelector((state) => state.data)
    const dispatch = useDispatch()
    const { setCurrentCalendarDate, setMealDate, setStatData, setAboutDate, fetchMealsData, clearData } = bindActionCreators({ ...DataActionCreators }, dispatch)

    useEffect(() => {
        setCurrantTiles(createDatesForMonth(data.currentCalendarDate, new Date(selectedPerson.created_at)))
    }, [selectedPerson])

    useEffect(() => {
        console.log(data)
        !data.loadingNewDates && setCurrantTiles(createDatesForMonth(data.currentCalendarDate, new Date(selectedPerson.created_at)))
    }, [data.loadingNewDates])

    const handleMonthBack = () => {
        
        const newDate = new Date(data.currentCalendarDate.getTime())
        newDate.setDate(5)
        newDate.setMonth(data.currentCalendarDate.getMonth() - 1)
        fetchMealsData(newDate.getMonth(), newDate.getFullYear(), selectedPerson.id)
        setCurrentCalendarDate(newDate)
        
    }

    function handleMonthForward() {
        const newDate = new Date(data.currentCalendarDate)
        newDate.setDate(5)
        if(data.currentCalendarDate.getMonth() == 11){
            newDate.setMonth(0)
            newDate.setFullYear(data.currentCalendarDate.getFullYear() + 1);
        }
        else
            newDate.setMonth(data.currentCalendarDate.getMonth() + 1)

        console.log(newDate)
        setCurrentCalendarDate(newDate)
        setCurrantTiles(createDatesForMonth(newDate, new Date(selectedPerson.created_at)))
    }

    function handleItemClicked(date) {
        if (selectedTab == "Meals") {
            if (equalDates(data.selectedMealDate, new Date(date)))
                return

            setMealDate(date)
        }
        else {
            if (equalDates(data.selectedDate, new Date(date)))
                return

            setAboutDate(date)
        }

        return
    }

    function handleMultItemClicked(date) {
        if (data.selectedStatDates.some((item) => equalDates(item, date)) == false) {
            setStatData([...data.selectedStatDates, date])
        }
        else {
            setStatData(data.selectedStatDates.filter((item) => {
                const isEqual = equalDates(item, date)
                return !isEqual
            }))
        }
    }

    function handleOnMouseOver(index) {
        setHoverID(index)
    }

    function handleOnMouseOut() {
        setHoverID(-1)
    }

    return (
        <Grid xs={12} container direction="row" className={classes.container} style={{ width: (datesMenuWidth + 1) + "px", height: (datesMenuWidth + 1) + "px", zIndex: "999", marginTop: "44px" }}>
            <Grid container xs={12} className={classes.header}>
                <KeyboardArrowLeftIcon onClick={isMonthInPast(new Date(selectedPerson.created_at), data.currentCalendarDate) ? handleMonthBack : () => { }} style={{ fontSize: "50px", color: "white" }} />
                <Typography variant="h5" style={{ color: "white" }}>{formatDateLabelShort(data.currentCalendarDate)}</Typography>
                <KeyboardArrowRightIcon onClick={isMonthInFuture(data.currentCalendarDate) ? () => { } : handleMonthForward} style={{ fontSize: "50px", color: "white" }} />
            </Grid>
            <Grid container xs={12} className={classes.daysLabels}>
                <Typography style={{ color: "white", position: "absolute", left: ((datesMenuWidth / 7) / 2 - 10) + "px" }}>{t('Sun')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 2) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Mon')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 3) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Tue')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 4) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Wed')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 5) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Thu')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 6) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Fri')}</Typography>
                <Typography style={{ color: "white", position: "absolute", left: (((datesMenuWidth / 7) * 7) - ((datesMenuWidth / 7) / 2) - 10) + "px" }}>{t('Sat')}</Typography>
            </Grid>
            {
                !data.loadingNewDates ?
                    <Grid container xs={12} style={{ height: "80%" }}>
                        {
                            selectedTab == 'Meals' || selectedTab == 'About patient' ?
                                currantTiles.map((item, index) => {
                                    return (
                                        <div
                                            onClick={() => item.type !== 'unused' ? handleItemClicked(item.date) : () => { }}
                                            key={index}
                                            onMouseOver={() => handleOnMouseOver(index)}
                                            onMouseLeave={() => handleOnMouseOut()}

                                            style={{
                                                width: (datesMenuWidth / 7) + "px",
                                                height: Math.round((datesMenuWidth * 0.8) / 6) + "px",
                                                backgroundColor: selectedTab == 'About patient' ? equalDates(data.selectedAboutDate, item.date) ? "rgba(138, 189, 255, 1)" : "white" : equalDates(data.selectedMealDate, item.date) ? "rgba(138, 189, 255, 1)" : "white",
                                                padding: "5px",
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                justifyContent: "space-between",
                                                alignContent: "space-between",
                                                cursor: item.type !== 'unused' ? "pointer" : ""
                                            }}
                                        >
                                            {
                                                item.type !== 'unused' ?
                                                    <>

                                                        <Typography style={{ color: item.type == "current" ? "black" : "gray", fontSize: "32px", display: "inline-block" }}>{item.date.getDate()}</Typography>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignContent: "space-between", flexWrap: "wrap" }}>
                                                            {
                                                                hoverID == index &&
                                                                data.data[(item.date.getMonth() + 1) + "" + item.date.getFullYear()][item.date.getDate() - 1].map((obrok, index2) => (
                                                                    <div
                                                                        key={index2 * 1000 + index}
                                                                        style={{
                                                                            height: datesMenuWidth == 350 ? "0px" : (datesMenuWidth / 65) + "px",
                                                                            width: datesMenuWidth == 350 ? "0px" : (datesMenuWidth / 65) + "px",
                                                                            backgroundColor: obrok.id ? (obrok.is_binge ? "orange" : "green") : "red",
                                                                            borderRadius: "50%",
                                                                            display: "inline-block",
                                                                            margin: "1px",
                                                                            alignSelf: "end"
                                                                        }}>
                                                                    </div>
                                                                ))

                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <Typography style={{ color: "gray", fontSize: "32px", display: "inline-block" }}>X</Typography>
                                            }
                                        </div>
                                    )
                                }) : null

                        }
                        {
                            selectedTab == 'Statistics' ?
                                currantTiles.map((item, index) => {
                                    return (
                                        <div
                                            onClick={() => item.type !== 'unused' ? handleMultItemClicked(item.date) : () => { }}
                                            style={{
                                                width: (datesMenuWidth / 7) + "px",
                                                height: Math.round((datesMenuWidth * 0.8) / 6) + "px",
                                                backgroundColor: data.selectedStatDates.some((date) => equalDates(date, item.date)) ? "rgba(138, 189, 255, 1)" : "white",
                                                padding: "5px",
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                cursor: item.type !== 'unused' ? "pointer" : ""
                                            }}
                                        >
                                            {
                                                item.type !== 'unused' ?
                                                    <Typography style={{ color: item.type == "current" ? "black" : "gray", fontSize: "32px" }}>{item.date.getDate()}</Typography>
                                                    :
                                                    <Typography style={{ color: "gray", fontSize: "32px" }}>X</Typography>
                                            }
                                        </div>
                                    )
                                }) : null

                        }
                    </Grid>
                    :
                    <Grid container xs={12} style={{ flex: 1, display: 'flex', height: "80%", alignItems: "center", justifyContent: "center" }}>
                        <div id="load">
                            <div>G</div>
                            <div>N</div>
                            <div>I</div>
                            <div>D</div>
                            <div>A</div>
                            <div>O</div>
                            <div>L</div>
                        </div>
                    </Grid>
            }
        </Grid>
    )
}

export default withStyles(styles)(PulloutCalendar)