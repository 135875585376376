export const styles = theme => ({
    root: {
        width: "100%",
        paddingTop: "44px",
        height: "100%",
        background: `linear-gradient(to top, rgba(157, 167, 233, 0.6),rgba(157, 167, 233, 0.75), rgba(157, 167, 233, 0.9), rgba(157, 167, 233, 1))`
    },
    headerContainer: {
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        width: "100%",
        paddingLeft: "55px"
    },
    cardsBig: {
        width: "200px",
        height: "140px",
        padding: "10px",
        margin: "10px",
        cursor: "pointer",
        borderColor: "black",
        alignItems: "space-evenly",
        justifyContent: "space-evenly"
    },
    cardsSmall: {
        width: "150px",
        height: "185px",
        padding: "10px",
        margin: "10px",
        cursor: "pointer",
        borderColor: "black"
    },
    cardsText: {
        textJustify: "center",
        textAlign: "center"
    },
    divider: {
        width: "99%",
        marginTop: "10px",
        marginBottom: "10px",
        alignSelf: "center",
        background: "black"
    },
    itemDivider: {
        width: "100%",
        background: "black",
        marginBottom: "5px",
        marginTop: "5px",
        alignSelf: "flex-end"
    },
    textContainer: {
        height: "200px",
        padding: "10px",
        backgroundColor: "transparent",
        boxShadow: "1px 2px 5px #0d25bf"
    },
    itemsContainer: {
        height: "420px",
        padding: "10px",
        backgroundColor: "transparent",
        boxShadow: "1px 2px 5px #0d25bf"
    },
    itemsScroll: {
        height: "310px",
        overflowY: "scroll",
        alignContent: "flex-start",
        ...theme.scrollProperties
    },
    emotionCardsContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
        padding: "5px",
        backgroundColor: "transparent",
        flexWrap: "wrap",
        boxShadow: "1px 2px 5px #0d25bf"
    }
});