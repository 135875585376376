import React from 'react'
import { convertMinutesIntoTime } from '../../utils/functions/dateHelpers';

export class CustomizedLabel extends React.PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {convertMinutesIntoTime(value)}
            </text>
        );
    }
}

export class CustomizedAxisTick extends React.PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} textAnchor="end" fill="#666" >
                    {convertMinutesIntoTime(payload.value)}
                </text>
            </g>
        );
    }
}