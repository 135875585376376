import i18next from 'i18next';
import english from "../../assets/translations/english.json";
import netherlands from "../../assets/translations/netherlands.json";
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
    lng: 'nl',
    resources: {
        en: english,
        nl: netherlands,
    },
    react: {
        useSuspense: false
    }
});

export default i18next;