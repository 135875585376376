export const styles = theme => ({
    root: {
        justifyContent: "space-evenly",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(157, 167, 233, 0.4),rgba(157, 167, 233, 0.75), rgba(157, 167, 233, 0.9), rgba(157, 167, 233, 1))`
    },
    container: {
        height: "100%",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    },
    divider: {
        height: "80%",
        backgroundColor: "black"
    },
    icon: {
        fontSize: "40px",
        marginBottom: "10px"
    },
    button: {
        border: "2px solid black",
        borderRadius: "10px",
        marginTop: "4px"
    }

});