export const styles = theme => ({
    scrollProperties: {
        position: "fixed",
        height: "100%",
        padding: "10px",
        paddingBottom: "180px",
        backgroundColor: "rgba(133, 133, 133)",
        filter: `brightness(90%)`,
        overflowY: 'scroll',
        ...theme.scrollProperties
    }
});