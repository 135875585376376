import {
    CLIENTS_LOADING,
    CLIENTS_SUCCESS,
    CLIENTS_FAILED,
    CLIENTS_PERSIST,
    CLIENTS_NEW_CLIENT,
    CLIENTS_NEW_ADMIN,
    CLIENTS_NEW_CLIENT_LOADING,
    CLIENTS_NEW_CLIENT_FAILED,
    CLIENTS_NEW_ADMIN_LOADING,
    CLIENTS_NEW_ADMIN_FAILED,
    CLIENTS_SET_DATACLIENT,
    CLIENTS_SET_NOTICLIENT,
    CLIENT_RESET
} from '../actions.js';

import { fetchMealsData, clearData } from './data.js';
import axios from '../../utils/axios/axios';
import i18next from '../../utils/i18n/i18n';

export const fetchClients = () => {
    return (dispatch) => {
        dispatch(loadingClients())
        axios
            .getInstance()
            .get("users/admin")
            .then((response) => {
                const { code, data } = response.data;
                if (code == 200) {
                    dispatch(loadingClientsSuccess());
                    dispatch(clientsPersist(data));
                }
                else {
                    dispatch(loadingClientsFailed());
                }
            })
            .catch(error => {
                dispatch(loadingClientsFailed(error));
                throw error;
            });
    };
};

export const makeUser = () => {
    return (dispatch) => {
        dispatch(loadingNewClient())
        axios
            .getInstance()
            .post("register/user")
            .then((response) => {
                const { code, data } = response.data;
                if (code == 200) {
                    dispatch(createdNewClient({ username: data.username, password: data.password }));
                    dispatch(fetchClients());
                }
                else {
                    dispatch(failedLoadingNewClient());
                    dispatch(fetchClients());
                }
            })
            .catch(error => {
                dispatch(failedLoadingNewClient());
                dispatch(fetchClients());
                throw error;
            });
    };
};

export const makeAdmin = (name, surname, email, username) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('name', name)
        formData.append('surname', surname)
        formData.append('email', email)
        formData.append('username', username)
        dispatch(loadingNewAdmin())
        axios
            .getInstance()
            .post("register/admin",
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                const { code, data } = response.data;
                if (code == 200) {
                    dispatch(createdNewAdmin({ name, surname, email, username, password: data.password }));
                }
                else {
                    dispatch(failedLoadingNewAdmin())
                }
            })
            .catch(error => {
                dispatch(failedLoadingNewAdmin())
                throw error;
            });
    };
};

export const setDataClient = (client) => {
    return (dispatch) => {
        const d = new Date();
        dispatch(dataClient(client));
        dispatch(clearData())
        dispatch(fetchMealsData(d.getMonth() + 1, d.getFullYear(), client.id));
        d.setDate(0)
        dispatch(fetchMealsData(d.getMonth() + 1, d.getFullYear(), client.id));
    };
};

export const setNotiClient = (client) => {
    return (dispatch) => {
        dispatch(notiClient(client))
    };
};

export const resetNotiDataClients = () => {
    return (dispatch) => {
        dispatch(clearData());
        dispatch(dataClient(null));
        dispatch(notiClient(null));
    }
}

export const resetCurrentClient = () => {
    return (dispatch) => {
        dispatch(resetClient());
    }
}

const resetClient = () => {
    return {
        type: CLIENT_RESET
    }
}

const loadingNewClient = () => {
    return {
        type: CLIENTS_NEW_CLIENT_LOADING,
        payload: i18next.t('NewClientWait')
    }
}

const failedLoadingNewClient = () => {
    return {
        type: CLIENTS_NEW_CLIENT_FAILED,
        payload: i18next.t('NewClientFailed')
    }
}

const loadingNewAdmin = () => {
    return {
        type: CLIENTS_NEW_ADMIN_LOADING,
        payload: i18next.t('NewAdminWait')
    }
}

const failedLoadingNewAdmin = () => {
    return {
        type: CLIENTS_NEW_ADMIN_FAILED,
        payload: i18next.t('NewAdminFailed')
    }
}


const createdNewClient = (client) => {
    return {
        type: CLIENTS_NEW_CLIENT,
        payload: client
    }
}

const createdNewAdmin = (admin) => {
    return {
        type: CLIENTS_NEW_ADMIN,
        payload: admin
    }
}

const loadingClients = () => {
    return {
        type: CLIENTS_LOADING,
        payload: i18next.t('ClientsLoading')
    }
}

const loadingClientsSuccess = () => {
    return {
        type: CLIENTS_SUCCESS,
        payload: i18next.t('ClientsSuccess')
    }
}

const loadingClientsFailed = (error = '') => {
    return {
        type: CLIENTS_FAILED,
        payload: {
            message: i18next.t('ClientsFailed'),
            errorMessage: error
        }
    }
}

const clientsPersist = (clients) => {
    return {
        type: CLIENTS_PERSIST,
        payload: clients
    }
}

const dataClient = (client) => {
    return {
        type: CLIENTS_SET_DATACLIENT,
        payload: client
    }
}

const notiClient = (client) => {
    return {
        type: CLIENTS_SET_NOTICLIENT,
        payload: client
    }
}