export const styles = theme => ({
    root: {
        height: '100%',
        alignItems: "center",
        justifyContent: "center",
        background: `linear-gradient(to right, rgba(157, 167, 233, 0.4),rgba(157, 167, 233, 0.75), rgba(157, 167, 233, 0.9), rgba(157, 167, 233, 1))`
    },
    container: {
        alignSelf: "flex-start",
        width: '100%',
        height: "100%"
    },
    form: {
        display: "flex",
        height: '100%',
        flexDirection: "column",
        alignItems: "center"
    }
});