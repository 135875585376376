export function formatMealName(id) {
    switch (id) {
        case 1: {
            return 'Breakfast'
        }
        case 2: {
            return '1stSnack'
        }
        case 3: {
            return 'Lunch'
        }
        case 4: {
            return '2ndSnack'
        }
        case 5: {
            return 'Dinner'
        }
        default: {
            return '3rdSnack'
        }
    }
}