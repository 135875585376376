import React from 'react';

import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

import { useTranslation } from "react-i18next";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function EmotionCard(props) {
    const { classes, stars, name } = props
    const emptyStars = 5 - stars

    const { t } = useTranslation();

    const render = [];
    for (let i = 0; i < stars; i++)
        render.push(<StarOutlinedIcon style={{ fontSize: "32" }}></StarOutlinedIcon>)
    for (let i = 0; i < emptyStars; i++)
        render.push(<StarBorderOutlinedIcon style={{ fontSize: "32" }}></StarBorderOutlinedIcon>)

    return (
        <div style={{ padding: "5px", justifyContent: "center" }}>
            <Paper variant="outlined" className={classes.container}>
                <Typography variant="h5" className={classes.headerText}>{t(name)}</Typography>
                <div className={classes.starsContainer}>
                    {
                        render
                    }
                </div>
            </Paper>
        </div>
    )
}

export default withStyles(styles)(EmotionCard);