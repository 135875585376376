import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import EmotionCard from '../../components/emotion-card/EmotionCard';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { useSelector } from 'react-redux';

import { useTranslation } from "react-i18next";

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { formatMealName } from '../../utils/functions/mealsHelpers';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function Meals(props) {
    const { classes, selectedPerson } = props
    const [selectedTab, setSelectedTab] = useState(0)
    const { width } = useWindowDimensions()

    const { t } = useTranslation()

    const data = useSelector((state) => state.data)
    const propery = data.selectedMealDate ? (data.selectedMealDate.getMonth() + 1) + '' + data.selectedMealDate.getFullYear() : null
    const meals = propery ? data.data[propery][data.selectedMealDate.getDate() - 1] : null

    useEffect(() => {
        setSelectedTab(0)
    }, [data.selectedMealDate])

    function handleItemClicked(id) {
        if (id) {
            if (selectedTab == id)
                setSelectedTab(0)
            else
                setSelectedTab(id)
        }
    }

    if (meals != null && selectedTab != 0)
        if (meals.some((meal) => meal.id == selectedTab) == false) {
            setSelectedTab(0)
            return <></>
        }

    let totalCal = 0;
    if (meals != null && meals != undefined)
        if (selectedTab != 0 && meals.some((meal) => meal.id == selectedTab) != false) {
            meals.find((item) => item.id === selectedTab).meal_items.forEach(item => { totalCal += Math.round(item.weight * item.kcal / 100) })
        }

    return (
        <div className={classes.root} >
            <div>
                <Grid container direction="column" style={{ height: "100%" }}>
                    <Grid container xs={12} direction="row" className={classes.headerContainer} >
                        {
                            selectedPerson != null && data.selectedMealDate ?
                                meals.map((item, index) => {
                                    return (
                                        <Grid item key={index}>
                                            <Paper
                                                onClick={() => handleItemClicked(item.id)}
                                                variant={item.id == selectedTab ? "outlined" : "elevation"}
                                                elevation={10}
                                                className={width > 720 ? classes.cardsBig : classes.cardsSmall}
                                                style={{ backgroundColor: item.id ? item.id == selectedTab ? "rgba(108, 123, 224, 1)" : "transparent" : "rgba(255, 56, 56, 0.65)" }}
                                            >
                                                <Grid container direction="column" style={{ height: "100%" }}>
                                                    <Grid item style={{ borderBottom: "2px solid black", marginBottom: "2px", alignContent: "center", alignItems: "center" }}>
                                                        <Typography variant="h5" className={classes.cardsText}>{t(formatMealName(index + 1))}</Typography>
                                                    </Grid>
                                                    {
                                                        item.id ?
                                                            <Grid container direction="row" style={{ marginTop: "2px", paddingTop: "5px" }}>
                                                                <Grid container xs={6} >
                                                                    <Typography variant="subtitle1" className={classes.cardsText}>{item.is_binge ? t('Binge') : t('NotBinge')}</Typography>
                                                                </Grid>

                                                                <Grid container xs={6} style={{ justifyContent: "right" }} >
                                                                    <Typography variant="subtitle1" className={classes.cardsText}>{t('Time')}: {item.delivery_at.slice(12, 16)}</Typography>
                                                                </Grid>
                                                            </Grid> : null
                                                    }
                                                    <Grid item style={{ alignItems: "center", justifyContent: "center" }}>
                                                        {
                                                            item.id ?
                                                                <Typography variant="h6" className={classes.cardsText} style={{ marginTop: width > 720 ? "0px" : "-1px" }}>{t('MealCommited')}</Typography>
                                                                : <Typography variant="h6" className={classes.cardsText} style={{ marginTop: "19px" }}>{t('MealNotCommited')}</Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )
                                }) :
                                <div>
                                    {
                                        selectedPerson ? null :
                                            <>
                                                <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "127px", fontSize: "50px" }} />
                                                <Typography variant="h5" style={{ position: "absolute", top: "137px", left: width > 1250 ? "370px" : "230px" }}>
                                                    {t('Tip1')}
                                                </Typography>
                                            </>

                                    }
                                    {
                                        (data.selectedStatDates && data.selectedStatDates.length > 0) ? null :
                                            !data.loadingNewDates && selectedPerson &&
                                            <>
                                                <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "198px", fontSize: "50px" }} />
                                                <Typography variant="h5" style={{ position: "absolute", top: "208px", left: width > 1250 ? "370px" : "230px" }}>
                                                    {t('Tip2')}
                                                </Typography>
                                            </>

                                    }
                                </div>
                        }
                    </Grid>

                    {selectedPerson !== '' && data.selectedMealDate ? <Divider className={classes.divider} /> : null}

                    <Grid container xs={12} direction="row" style={{ width: "100%" }}>
                        <Grid item xs={width > 1100 ? 6 : 12} style={{ padding: "10px" }}>
                            {
                                selectedTab != 0 && meals != null ?
                                    <>
                                        <Paper variant="elevation" elevation={8} className={classes.textContainer} style={{ marginBottom: "20px" }}>
                                            <Typography variant="h6" style={{ textAlign: "center", marginBottom: "5px" }}>{t('Thoughts')}:</Typography>
                                            <Divider style={{ marginBottom: "5px", background: "black" }} />
                                            <Typography variant="h6">{meals.find((item) => item.id === selectedTab).thoughts}</Typography>
                                        </Paper>
                                        <Paper variant="elevation" elevation={8} className={classes.textContainer}>
                                            <Typography variant="h6" style={{ textAlign: "center", marginBottom: "5px" }}>{t('Feelings')}:</Typography>
                                            <Divider style={{ marginBottom: "5px", background: "black" }} />
                                            <Typography variant="h6" >{meals.find((item) => item.id === selectedTab).feelings}</Typography>
                                        </Paper>
                                    </>
                                    : null
                            }
                        </Grid>
                        <Grid container direction="column" xs={width > 1100 ? 6 : 12} style={{ padding: "10px", width: "100%", justifyContent: "space-between" }}>
                            {
                                selectedTab != 0 ?
                                    <>
                                        <Paper variant="elevation" elevation={8} className={classes.itemsContainer} >
                                            <Grid container direction="row" >
                                                <Grid container direction="row" style={{ justifyContent: "space-around", alignContent: "space-evenly" }}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" style={{ display: "inline-block" }}>{t('Name')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} >
                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{t('kcal100g')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{t('grams')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{t('kcal')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ width: "100%", background: "black", marginBottom: "5px", marginTop: "5px" }} />
                                                <Grid container xs={12} direction="row" className={classes.itemsScroll} >
                                                    {
                                                        meals != null &&
                                                        meals.find((item) => item.id === selectedTab).meal_items.map(item => {
                                                            return (
                                                                <Grid container direction="row" style={{ justifyContent: "space-around", alignContent: "space-evenly" }}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant="h6" style={{ display: "inline-block" }}>{item.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2} >
                                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{item.kcal}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{item.weight}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant="h6" style={{ textAlign: "center" }}>{Math.round(item.weight * item.kcal / 100)}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                <Divider className={classes.itemDivider} />
                                                <Grid item xs={10}>
                                                    <Typography variant="h5" style={{ display: "inline-block" }}>{t('TotalCalories')}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="h5" style={{ textAlign: "center", fontWeight: "600" }}>{totalCal}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </> : null
                            }
                        </Grid>
                        {
                            selectedTab != 0 ?
                                <Grid container xs={12} direction="row" justifyContent="space-evenly" style={{ padding: "10px", marginTop: "10px" }}>
                                    <Paper elevation={8} className={classes.emotionCardsContainer}>
                                        {
                                            meals != null &&
                                                meals.find((item) => item.id === selectedTab).emotions.length > 0 ?
                                                meals.find((item) => item.id === selectedTab).emotions.map((emotion, index) => {
                                                    return <EmotionCard key={index} name={emotion.type} stars={emotion.star}></EmotionCard>
                                                })
                                                :
                                                <div style={{ padding: "5px", justifyContent: "center" }}>
                                                    <Paper variant="outlined" style={{ backgroundColor: "transparent", padding: '10px' }} >
                                                        <Typography style={{ backgroundColor: 'transparent' }}>{t('NoEmotion')}</Typography>
                                                    </Paper>
                                                </div>
                                        }
                                    </Paper>
                                </Grid> : null
                        }
                    </Grid>
                </Grid>
            </div>
        </div >

    )
}

export default withStyles(styles)(Meals)
