export const styles = theme => ({
    container: {
        justifyContent: "center",
        alignContent: "center",
        height: "100%",
        backgroundColor: "rgba(255,255,255,0.5)"
    },
    exitButton: {
        display: "block",
        position: "absolute",
        right: "50px",
        top: "120px",
        fontSize: "35px"
    },
    text: {
        textAlign: 'center',
        paddingBottom: "30px"
    }
})