import React from 'react'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


function SideBarHeading(props) {
    const { heading } = props;
    return (
        <Grid container xs={12} direction="column" style={{ alignItems: "center", height: "40px", justifyContent: "space-around", marginBottom: "5px" }}>
            <Typography variant="h6" style={{ color: "white" }}>{heading}</Typography>
            <Divider style={{ width: "100%", backgroundColor: "white" }}></Divider>
        </Grid>
    )
}

export default SideBarHeading