import React from 'react'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { useTranslation } from "react-i18next";

function SideBarItem(props) {
    const { children, name, pressHandler, selectedItem } = props;
    const { width } = useWindowDimensions()
    const { t } = useTranslation();
    console.log(name,t(name))
    return (
        <Paper
            elevation={selectedItem == name ? 6 : 0}
            onClick={() => pressHandler(name)}
            style={{
                background: selectedItem == name ? "rgb(63,72,204,0.8)" : "transparent",
                boxShadow: selectedItem == name ? "1px 1px 2px rgb(63,72,204,1)" : null,
                width: "100%",
                height: "100%",
                marginTop: "5px",
                paddingLeft: "5px",
                cursor: "pointer"
            }}>
            <Grid container xs={12} direction="row" style={{ alignItems: "center", height: "100%" }}>
                {children}
                {
                    width > 1250 ?
                        <Typography style={{ display: "inline-block", color: "white", fontSize: "1.25em" }}>{t(name)}</Typography>
                        : null
                }
            </Grid>
        </Paper>
    )
}

export default SideBarItem