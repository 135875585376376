import {
    DATA_LOADING,
    DATA_SUCCESS,
    DATA_FAILED,
    DATA_PERSIST,
    DATA_UPDATE_MEAL_DATE,
    DATA_UPDATE_ABOUT_DATE,
    DATA_UPDATE_STAT_DATES,
    DATA_UPDATE_CALENDAR_DATE,
    DATA_CLEAR,
    DATA_DAILY_INTAKE,
    DATA_DAILY_INTAKE_LOADING,
    DATA_DAILY_INTAKE_FINISH
} from '../actions.js';
import { emptyMonthlyMeals } from '../../assets/constants/constantsTemplates';
import axios from '../../utils/axios/axios';
import i18next from '../../utils/i18n/i18n';

export const fetchMealsData = (month, year, userID) => {
    return async (dispatch) => {
        dispatch(loadingData())
        const language = i18next.language;
        if(parseInt(month)<1)
        {
            month=12;
            year-=1;
        }
                    
        axios
            .getInstance()
            .get(`users-meals/month/${userID}?filter%5Bdate%5D=1-${month}-${year}&language=${language}`)
            .then((response) => {
                const { code, data } = response.data;
               
                if (code == 200) {
                    
                    dispatch(dataPersist(data, month, year));
                    dispatch(loadingDataSuccess());
                }
                else {
                    dispatch(dataPersist(emptyMonthlyMeals, month, year));
                    dispatch(loadingDataFailed());
                }
            })
            .catch(error => {
                dispatch(dataPersist(emptyMonthlyMeals, month, year));
                dispatch(loadingDataFailed(error));
                throw error;
            });
    };
};

export const fetchDailyIntake = (userID,day, month, year) => {
    return (dispatch) => {
        dispatch(loadingDailyIntake());

        axios
            .getInstance()
            .get(`daily-intake-admin/${userID}?filter%5Bdate%5D=${day}-${month}-${year}`)
            .then(response => {
                console.log("response ----",response.data)
                if(response.data.code === 200)
                {
                    dispatch(dailyIntakeData(response.data.data))
                    dispatch(finishLoadingDailyIntake());
                }
                else{
                    dispatch(finishLoadingDailyIntake());
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    };

};

export const setMealDate = (data) => {
    return (dispatch) => {
        dispatch(mealDate(data))
    };
};

export const setAboutDate = (data) => {
    return (dispatch) => {
        dispatch(aboutDate(data))
    }
}

export const setStatData = (data) => {
    return (dispatch) => {
        dispatch(statData(data))
    };
};

export const clearData = () => {
    return (dispatch) => {
        dispatch(clear())
    };
};

export const setCurrentCalendarDate = (date) => {
    return (dispatch) => {
        dispatch(setCalendarDate(date))
    }
}

export const dailyIntakeData = (data) => {
    return (dispatch) => {
        dispatch(dailyIntake(data))
    }
}

export const loadingDailyIntakeData = () => {
    return (dispatch) => {
        dispatch(loadingDailyIntake())
    }
}

export const finishLoadingDailyIntakeData = () => {
    return (dispatch) => {
        dispatch(finishLoadingDailyIntake())
    }
}

const setCalendarDate = (date) => {
    return {
        type: DATA_UPDATE_CALENDAR_DATE,
        payload: date
    }
}
const clear = () => {
    return {
        type: DATA_CLEAR,
    }
}

const loadingData = () => {
    return {
        type: DATA_LOADING,
        payload: i18next.t('DataLoading')
    }
}

const loadingDataSuccess = () => {
    return {
        type: DATA_SUCCESS,
        payload: i18next.t('DataSuccess')
    }
}

const loadingDataFailed = (error = '') => {
    return {
        type: DATA_FAILED,
        payload: {
            message: i18next.t('DataFailed'),
            errorMessage: error
        }
    }
}

const dataPersist = (data, month, year) => {
    return {
        type: DATA_PERSIST,
        payload: { data, month, year }
    }
}

const mealDate = (data) => {
    return {
        type: DATA_UPDATE_MEAL_DATE,
        payload: data
    }
}

const aboutDate = (data) => {
    return {
        type: DATA_UPDATE_ABOUT_DATE,
        payload: data
    }
}

const statData = (data) => {
    return {
        type: DATA_UPDATE_STAT_DATES,
        payload: data
    }
}

const dailyIntake = (data) => {
    return {
        type: DATA_DAILY_INTAKE,
        payload: data
    }
}

const loadingDailyIntake = () => {
    return {
        type: DATA_DAILY_INTAKE_LOADING
    }
}
const finishLoadingDailyIntake = () => {
    return {
        type: DATA_DAILY_INTAKE_FINISH
    }
}