export const styles = theme => ({
    container: {
        padding: "0px",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "transparent",
        borderColor: "transparent"
    },
    headerText: {
        alignSelf: "center",
        marginRight: "0px",
        textAlign: "center"
    },
    starsContainer: {
        paddingTop: "2px"
    }
})