import React, { useState } from 'react'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import withGutters from '../../HOC/withGutters/withGutters';

import * as AuthActionCreators from '../../store/actions/auth';

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function LogIn({ classes }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const { t } = useTranslation()

    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const { tryLogIn } = bindActionCreators({ ...AuthActionCreators }, dispatch)

    const handleLogIn = () => {
        tryLogIn(username, password)
    }

    return (
        <Grid container xs={12} className={classes.root}>
            <Paper elevation={10} className={classes.container} >
                <Grid container xs={12} className={classes.smallSegments} style={{ borderBottom: `2px solid black` }} >
                    <Typography variant="h4" style={{ display: "inline-block", textAlign: "center" }}>{t('LogIn')}</Typography>
                </Grid>

                <Grid container xs={12} direction="column" className={classes.bigSegment} >
                    <Grid container xs={12} direction="column" style={{ flex: 2, justifyContent: "space-around", alignItems: "center" }}>
                        <LockOpenOutlinedIcon className={classes.icon} />
                        {
                            auth.message == "" ?
                                <Typography variant="h5" style={{ marginTop: 10, textAlign: "center" }}>
                                    {t('LogInMessage2')}
                                </Typography>
                                :
                                <Typography variant="h5" style={{ marginTop: 10 }}>
                                    {auth.message}
                                </Typography>
                        }
                    </Grid>
                    <Grid container xs={12} direction="column" style={{ flex: 5, alignContent: "center", justifyContent: "center", width: "100%" }}>
                        <TextField id="username" label="Username" variant="outlined" type="email" value={username} onChange={(e) => { setUsername(e.target.value) }} style={{ margin: "10px" }} />
                        <TextField id="password" label="Password" variant="outlined" type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} style={{ margin: "10px" }} />
                        <Button variant="contained" style={{}} onClick={() => handleLogIn()} >{t('LogIn')}</Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.smallSegments} style={{ borderTop: `2px solid black` }} >
                    <Typography variant="h6" style={{ display: "inline-block", textAlign: "center" }}>{t('LogInMessage1')}</Typography>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withGutters(withStyles(styles)(LogIn))
