export const styles = theme => ({
    title: {
        color: "inherit",
        "&:hover,&:focus": {
            color: "inherit",
            background: "transparent"
        },

    },
    container: {
        padding: "20px",
        margin: "20px",
        backgroundColor: "#3f64dce9"
    },
    miniContainer: {
        display: "inline-block"
    },
    paperContainer: {
        padding: "20px",
        backgroundColor: "#3f64dce9",
        margin: "20px"
    },
    miniHeading: {
        display: "inline-block",
        marginBottom: "10px",
        color: "white"
    },
    miniText: {
        textAlign: "justify",
        padding: 10,
        color: "white"
    }
});