export const styles = theme => ({
    container: {
        position: "fixed",
        backgroundColor: "#4676C3",
    },
    header: {
        height: "15%",
        borderBottomWidth: "1px",
        borderBottomColor: "black",
        borderBottomStyle: "solid",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#4676C3",
    },
    daysLabels: {
        height: "5%",
        borderBottomWidth: "1px",
        borderBottomColor: "black",
        borderBottomStyle: "solid",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#4676C3"
    }
});