import React from 'react'

import { CartesianGrid, XAxis, YAxis, BarChart, Tooltip, Bar } from 'recharts';

import { useTranslation } from "react-i18next";

import BigChartContainer from '../big-chart-container/BigChartContainer';

function StatisticsBingeBars(props) {

    const { height, width, goBack, bingesData } = props;

    const { t } = useTranslation()

    const minwidth = width * 0.7;
    const minheight = height * 0.7;

    return (
        <BigChartContainer goBack={goBack} title={t('Binges')}>
            <BarChart width={minwidth} height={minheight} data={bingesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="v" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="binges" fill="#8884d8" maxBarSize={230} />
            </BarChart>
        </BigChartContainer>
    )
}

export default StatisticsBingeBars