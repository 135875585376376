import {
    CLIENTS_LOADING,
    CLIENTS_SUCCESS,
    CLIENTS_FAILED,
    CLIENTS_PERSIST,
    CLIENTS_NEW_CLIENT,
    CLIENTS_NEW_CLIENT_LOADING,
    CLIENTS_NEW_CLIENT_FAILED,
    CLIENTS_NEW_ADMIN_LOADING,
    CLIENTS_NEW_ADMIN_FAILED,
    CLIENTS_NEW_ADMIN,
    CLIENTS_SET_DATACLIENT,
    CLIENTS_SET_NOTICLIENT,
    CLIENT_RESET
} from '../actions.js';

const initialState = {
    clientsData: [],
    clientsNoti: [],
    selectedDataClient: null,
    selectedNotiClient: null,
    newClientCreated: null,
    newClientLoading: false,
    newClientMessage: "",
    newAdminCreated: null,
    newAdminLoading: false,
    newAdminMessage: "",
    message: "",
    errorMessage: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLIENTS_LOADING:
            return { ...state, message: action.payload, errorMessage: "" };

        case CLIENTS_SUCCESS:
            return { ...state, message: action.payload.message, authenticated: true };

        case CLIENTS_FAILED:
            return { ...state, message: action.payload.message, errorMessage: action.payload.errorMessage }

        case CLIENTS_PERSIST: {
            const clientsNotis = [{ id: 0, name: "Everyone" }];
            return { ...state, clientsData: [...action.payload], clientsNoti: [...clientsNotis, ...action.payload] }
        }

        case CLIENTS_NEW_CLIENT:
            return { ...state, newClientCreated: { ...action.payload }, newClientLoading: false, newClientMessage: "" }

        case CLIENTS_NEW_CLIENT_LOADING:
            return { ...state, newClientLoading: true, newClientCreated: null, newClientMessage: action.payload }

        case CLIENTS_NEW_CLIENT_FAILED:
            return { ...state, newClientLoading: false, newClientMessage: action.payload }

        case CLIENTS_NEW_ADMIN:
            return { ...state, newAdminCreated: { ...action.payload }, newAdminLoading: false, newAdminMessage: "" }

        case CLIENTS_NEW_ADMIN_LOADING:
            return { ...state, newAdminLoading: true, newAdminCreated: null, newAdminMessage: action.payload }

        case CLIENTS_NEW_ADMIN_FAILED:
            return { ...state, newAdminLoading: false, newAdminMessage: action.payload }

        case CLIENTS_SET_DATACLIENT:
            return { ...state, selectedDataClient: action.payload }

        case CLIENTS_SET_NOTICLIENT:
            return { ...state, selectedNotiClient: action.payload }

        case CLIENT_RESET:
            return { ...initialState };

        default:
            return state;
    }
}