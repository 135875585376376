import './App.css';
import React, { useEffect } from 'react';
import StartingPage from './containers/starting-page/StartingPage'
import LogIn from './containers/log-in/LogIn'
import Navbar from './components/navbar/Navbar';
import Home from './containers/home/Home'
import withMaterials from './HOC/withMaterial/withMaterial'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Route, Switch, Redirect } from "react-router-dom";

function App() {

  const authenticated = useSelector((state) => state.auth.authenticated)
  const { i18n } = useTranslation()

  useEffect(() => {
    const setLanguage = async () => {
      i18n.changeLanguage('en');
    }
    setLanguage();
  }, [])

  return (
    <div className="App">
      <Navbar>
        <React.Fragment>
          {
            !authenticated ?
              <Switch>
                <Route path="/starting-page" exact>
                  <StartingPage />
                </Route>
                <Route path="/login" exact>
                  <LogIn />
                </Route>
                <Redirect to="/starting-page" />
              </Switch>
              :
              <Switch>
                <Route path="/home" exact>
                  <Home />
                </Route>
                <Redirect to="/home" />
              </Switch>
          }
        </React.Fragment>
      </Navbar>
    </div>
  );
}

export default withMaterials(App);
