import {
    USER_INFO,
    USER_EMAIL,
    USER_PASSWORD_LOADING,
    USER_PASSWORD_FAILED,
    USER_PASSWORD_SUCCESS,
    USER_EMAIL_LOADING,
    USER_EMAIL_FAILED,
    USER_RESET
} from "../actions.js";
import axios from '../../utils/axios/axios';
import i18next from '../../utils/i18n/i18n';

export const getCredentials = () => {
    return (dispatch) => {
        axios
            .getInstance()
            .get("admin/data")
            .then((response) => {
                const { code } = response.data;
                if (code == 200) {
                    dispatch(persistInfo(response.data.data['name'], response.data.data['surname'], response.data.data['email']))
                }
            })
            .catch(error => {
                throw error;
            });
    };
};

export const changePassword = (oldPassword, newPassword) => {
    return (dispatch) => {
        const formData = new URLSearchParams();
        formData.append('current', oldPassword)
        formData.append('password', newPassword)
        dispatch(passwordLoading())
        axios
            .getInstance()
            .patch(
                "settings/change-password",
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then(async (response) => {
                const { code } = response.data;
                if (code == 200) {
                    dispatch(passwordSuccess())
                } else {
                    dispatch(passwordFailed())
                }
            })
            .catch(error => {
                dispatch(passwordFailed())
                throw error;
            });
    };
};

export const changeEmail = (oldMail, newMail) => {
    return (dispatch) => {
        const formData = new URLSearchParams();
        formData.append('current', oldMail)
        formData.append('email', newMail)
        dispatch(emailLoading())
        axios
            .getInstance()
            .patch(
                "settings/change-email",
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                const { code } = response.data;
                if (code == 200) {
                    dispatch(changeAdminMail(response.data.data))
                } else {
                    dispatch(emailFailed())
                }
            })
            .catch(error => {
                dispatch(emailFailed())
                throw error;
            });
    };
};

export const resetUser = () => {
    return (dispatch) => {
        dispatch(resetCurrentUser());
    }
}

const resetCurrentUser = () => {
    return {
        type: USER_RESET
    }
}

const changeAdminMail = (email) => {
    return {
        type: USER_EMAIL,
        payload: {
            email,
            message: i18next.t('EmailSuccess')
        }
    }
}

const persistInfo = (name, surname, email) => {
    return {
        type: USER_INFO,
        payload: { name, surname, email }
    }
}

const passwordLoading = () => {
    return {
        type: USER_PASSWORD_LOADING,
        payload: i18next.t('PasswordWait')
    }
}

const passwordFailed = () => {
    return {
        type: USER_PASSWORD_FAILED,
        payload: i18next.t('PasswordFailed')
    }
}

const passwordSuccess = () => {
    return {
        type: USER_PASSWORD_SUCCESS,
        payload: i18next.t('PasswordSuccess')
    }
}

const emailLoading = () => {
    return {
        type: USER_EMAIL_LOADING,
        payload: i18next.t('EmailWait')
    }
}

const emailFailed = () => {
    return {
        type: USER_EMAIL_FAILED,
        payload: i18next.t('EmailFailed')
    }
}


