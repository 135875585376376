import {
    USER_INFO,
    USER_EMAIL,
    USER_PASSWORD_LOADING,
    USER_PASSWORD_FAILED,
    USER_PASSWORD_SUCCESS,
    USER_EMAIL_LOADING,
    USER_EMAIL_FAILED,
    USER_RESET
} from '../actions.js';

const initialState = {
    name: "",
    surname: "",
    email: "",
    loadingPassword: false,
    passwordMessage: "",
    loadingEmail: false,
    emailMessage: ""
}

export default (state = initialState, action) => {

    switch (action.type) {

        case USER_INFO:
            return { ...state, name: action.payload.name, surname: action.payload.surname, email: action.payload.email };

        case USER_EMAIL:
            return { ...state, email: action.payload.email, emailMessage: action.payload.message, loadingEmail: false };

        case USER_EMAIL_LOADING:
            return { ...state, loadingEmail: true, emailMessage: action.payload };

        case USER_EMAIL_FAILED:
            return { ...state, emailMessage: action.payload, loadingEmail: false };

        case USER_PASSWORD_LOADING:
            return { ...state, loadingPassword: true, passwordMessage: action.payload };

        case USER_PASSWORD_FAILED:
            return { ...state, passwordMessage: action.payload, loadingPassword: false };

        case USER_PASSWORD_SUCCESS:
            return { ...state, passwordMessage: action.payload, loadingPassword: false };

        case USER_RESET:
            return { ...initialState };

        default:
            return state;
    }
}