import { formatDateCharts, formatDateToMinutes } from './dateHelpers'
const foodChartColors = [
    "#d72d2d",
    "#b22dd7",
    "#bbd72d",
    "#2dd777",
    "#2db5d7",
    "#d7902d"
]
export function getBingesForDates(selectedStatDates, data) {
    let binges = [];
    selectedStatDates.forEach((date) => {
        const meals = data[(date.getMonth() + 1) + '' + date.getFullYear()][date.getDate() - 1]
        let countBinges = 0;
        meals.forEach(meal => {
            if (meal.is_binge)
                countBinges++
        })
        binges.push({ name: "", binges: countBinges, v: formatDateCharts(date) })
    })
    return binges
}
export function getCaloriesForDates(selectedStatDates, data) {
    let calories = [];
    if (selectedStatDates.length > 1) {
        selectedStatDates.forEach((date) => {
            const meals = data[(date.getMonth() + 1) + '' + date.getFullYear()][date.getDate() - 1]
            let countCalories = 0;
            meals.forEach(meal => {
                meal.meal_items.forEach((item) => {
                    countCalories += item.kcal * item.weight / 100;

                })
            })
            calories.push({ date: formatDateCharts(date), calories: Math.round(countCalories) })
        })
        return calories
    }

    if (selectedStatDates.length == 1) {
        const meals = data[(selectedStatDates[0].getMonth() + 1) + '' + selectedStatDates[0].getFullYear()][selectedStatDates[0].getDate() - 1]
        meals.forEach((meal) => {
            let countCalories = 0;
            meal.meal_items.forEach(item => {
                countCalories += item.kcal * item.weight / 100;
            })
            calories.push({ name: meal.type, calories: countCalories })
        })
    }

    return calories
}

export function getTimeLineForDates(selectedStatDates, data) {
    let timeLine = [];
    selectedStatDates.forEach((date) => {
        const meals = data[(date.getMonth() + 1) + '' + date.getFullYear()][date.getDate() - 1]
        let outputData = { date: date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear(), breakfast: null, snack1: null, lunch: null, snack2: null, dinner: null, snack3: null };
        meals.forEach(meal => {
            switch (meal.meal_type) {
                case 1: {
                    outputData['breakfast'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                case 2: {
                    outputData['snack1'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                case 3: {
                    outputData['lunch'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                case 4: {
                    outputData['snack2'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                case 5: {
                    outputData['dinner'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                case 6: {
                    outputData['snack3'] = formatDateToMinutes(meal.delivery_at)
                    break;
                }
                default: {

                }
            }
        })
        timeLine.push(outputData)
    })
    return timeLine
}

export function getSkippedMealsForDates(selectedStatDates, data) {
    let skippedMeals =
        [
            {
                "name": "Breakfast",
                "value": 0
            },
            {
                "name": "Snack",
                "value": 0
            },
            {
                "name": "Lunch",
                "value": 0
            },
            {
                "name": "Snack",
                "value": 0
            },
            {
                "name": "Dinner",
                "value": 0
            },
            {
                "name": "Snack",
                "value": 0
            }
        ]
    selectedStatDates.forEach((date) => {
        const meals = data[(date.getMonth() + 1) + '' + date.getFullYear()][date.getDate() - 1]
        meals.forEach((meal, index) => {
            skippedMeals[index]["value"] += meal.id ? 0 : 1;
        })
    })
    return skippedMeals
}

export function getColorForPie(id) {
    return foodChartColors[id]
}