const daysOfWeekLong =
{
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday'
}
const daysOfWeekShort =
{
    '0': 'Sun',
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat'
}
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export function formatDateCharts(date) {
    return ('0' + date.getDate()).slice(-2) + ". " + ('0' + parseInt(date.getMonth() + 1)).slice(-2) + ". " + (date.getFullYear() + '').slice(-2)
}
export function formatDateLabelLong(date) {
    return daysOfWeekLong[date.getDay()] + ", " + date.getDate() + ". " + monthNames[date.getMonth()] + ", " + date.getFullYear();
}
export function formatDateLabelShort(date) {
    return monthNames[date.getMonth()] + ", " + date.getFullYear();
}
// const test = { '2021': { '0': [2, 4, 7], '1': [1, 2, 31] }, '2022': { '4': [7, 2, 9] } }
export function formatDatesLabel(dates) {
    if (dates === undefined || dates.length == 0)
        return ''

    let label = ''
    let formatedDates = {}
    dates.sort((a, b) => {
        return a - b
    })
    dates.forEach((date) => {
        const yearPropery = date.getFullYear()
        const monthPropery = date.getMonth()
        if (formatedDates.hasOwnProperty(yearPropery)) {
            if (formatedDates[yearPropery].hasOwnProperty(monthPropery)) {
                formatedDates[yearPropery][monthPropery].push(date.getDate())
            } else {
                formatedDates[yearPropery][monthPropery] = [date.getDate()]
            }
        } else {
            formatedDates[yearPropery] = {}
            formatedDates[yearPropery][monthPropery] = [date.getDate()]
        }
    })

    for (let year in formatedDates) {
        label += year + ": [";
        for (let month in formatedDates[year]) {

            formatedDates[year][month].forEach((item) => {
                label += item + '. ';
            })
            label = label.slice(0, -1);

            label += monthNames[month] + "/";
        }
        label = label.slice(0, -1);
        label += "], ";
    }
    label = label.slice(0, -2);
    return (label)
}
export function convertMinutesIntoTime(time) {

    if (time == null) {
        return 'NoN'
    }

    let hours = ('0' + parseInt(parseInt(time) / 60)).slice(-2)
    let minutes = ('0' + parseInt(time) % 60).slice(-2)

    return hours + ":" + minutes
}

export function isMonthInFuture(date) {
    if (date == null) return true
    const todaysDate = new Date()
    if (date.getFullYear() < todaysDate.getFullYear()) {
        return false
    }
    if (date.getFullYear() == todaysDate.getFullYear() && date.getMonth() < todaysDate.getMonth()) {
        return false
    }
    return true
}

export function isMonthInPast(borderDate, currentDate) {
    if (borderDate == null) return true
    if (currentDate == null) return true
    if (borderDate.getFullYear() >= currentDate.getFullYear() && borderDate.getMonth() >= currentDate.getMonth()) {
        return false
    }
    return true
}

export function formatDateToMinutes(date) {
    if (date == null || date == undefined) return ''
    return parseInt(date.slice(11, 13)) * 60 + parseInt(date.slice(14, 16))
}