import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import NotificationsIcon from '@material-ui/icons/Notifications';

import { useSelector } from 'react-redux';

import PulloutScrollContainer from "../../components/pullout-scroll-container/PulloutScrollContainer"

import axios from '../../utils/axios/axios';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import { useTranslation } from "react-i18next";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

function Notifications(props) {

    const { classes, users } = props
    const [notificationText, setNotificationText] = useState('')
    const [statusText, setStatusText] = useState('')
    const [open, setOpen] = React.useState(false);
    const { width } = useWindowDimensions();

    const { t } = useTranslation()

    const selectedNotiClient = useSelector((state) => state.clients.selectedNotiClient)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const textOnTimer = (text) => {
        setStatusText(text);
        setTimeout(function () {
            setStatusText('')
        }, 2000);
    }

    const handleSendNotification = () => {
        if (selectedNotiClient != null) {
            if (notificationText.length > 1) {
                handleClickOpen()
                return
            }
            textOnTimer(t('NotiNoMessage'))
            return
        }
        textOnTimer(t('NotiNoClient'))
    }

    const handleCloseAndSend = () => {
        setOpen(false);
        setStatusText(t('NotiWait'))
        const formData = new FormData();
        formData.append('user_id', selectedNotiClient.id)
        formData.append('description', notificationText)
        axios
            .getInstance()
            .post(
                "notification",
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                const { code } = response.data;
                if (code == 200) {
                    textOnTimer(t('NotiSuccess'))
                    setNotificationText('')
                }
                else {
                    textOnTimer(t('NotiFailed'))
                }
            })
            .catch(error => {
                textOnTimer(t('NotiError'))
                throw error;
            });
    };

    return (
        <Grid container direction="row" className={classes.root}>
            <div className={classes.container}>
                <PulloutScrollContainer varient="account" usersMenuWidth={width < 600 ? 100 : 160} users={users} />
                <div className={classes.form} style={{ paddingLeft: width < 600 ? '100px' : '160px' }}>
                    <NotificationsIcon style={{ fontSize: "40px", margin: "20px" }} />
                    <Typography variant="h3" style={{ marginBottom: "5px" }}>{t('Notifications')}</Typography>
                    <Typography style={{ textAlign: "center" }}>{t('NotiSlecetClient')}</Typography>
                    <Typography style={{ textAlign: "center" }}>{t('NotiWriteMessage')}</Typography>
                    <TextField
                        id="noti-text"
                        label={t('NotiText')}
                        multiline
                        variant="outlined"
                        rows={10}
                        style={{ minWidth: "250px", width: width > 750 ? "500px" : "200px", minHeight: "300px", marginTop: "60px" }}
                        value={notificationText}
                        onChange={(e) => { setNotificationText(e.target.value) }}
                    />
                    <Typography variant="h5" style={{ marginBottom: "10px" }}> {statusText} </Typography>
                    {
                        statusText == '' && <Button onClick={handleSendNotification} style={{ border: "2px solid black", borderRadius: "10px" }}>{t('SendNotification')}</Button>
                    }
                </div>
            </div>
            {
                selectedNotiClient &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Notifications"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('NotiDialog')} {selectedNotiClient.name} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('Dismiss')}</Button>
                        <Button onClick={handleCloseAndSend} autoFocus>{t('Send')}</Button>
                    </DialogActions>
                </Dialog>
            }

        </Grid>
    )
}

export default withStyles(styles)(Notifications)
