import React,{useEffect, useState} from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from "react-i18next";

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";
import EmotionStars from '../../components/emotion-stars/EmotionStars';

import * as dailyIntakeActionCreators from "../../store/actions/data"


import { formatDateLabelLong, formatDatesLabel } from '../../utils/functions/dateHelpers';
import { Tune } from '@material-ui/icons';



function AboutPatient(props) {
    const { classes, selectedPerson } = props
    
    const { width } = useWindowDimensions()

    const { t } = useTranslation()

    const data = useSelector((state) => state.data)
    const dispatch = useDispatch()
    const { fetchDailyIntake } = bindActionCreators({ ...dailyIntakeActionCreators }, dispatch)

    let tableData =[];
    let tableTitle =[];
    let selectedDay=0;
    let selectedDayOfMount=0;

    let rows = [];

    const [openModal,setOpenModal] = useState(false)

    if(data.selectedAboutDate)
    {
        const selectedDate = new Date(data.selectedAboutDate.getFullYear(),(data.selectedAboutDate.getMonth()),data.selectedAboutDate.getDate(),0,0,0,0)
        
        selectedDay = selectedDate.getDay()
        selectedDayOfMount = selectedDate.getDate()

        let daysInMonth = null;

        for(var i=0;i<7;i++)
        {
            let propery=null;
            let meals=null;
            daysInMonth = new Date(data.selectedAboutDate.getFullYear(), data.selectedAboutDate.getMonth(), 0).getDate();
            
            if(data.selectedAboutDate.getDate() - selectedDay + i < 1 && data.selectedAboutDate.getMonth() !== 0)
            {
                propery = data.selectedAboutDate ? (data.selectedAboutDate.getMonth()) + '' + data.selectedAboutDate.getFullYear() : null
                meals = propery ? data.data[propery][daysInMonth - 1 - (selectedDay-data.selectedAboutDate.getDate()) + i] : null
            }
            if(data.selectedAboutDate.getDate() + i > daysInMonth && data.selectedAboutDate.getMonth() === 0)
            {
                daysInMonth = new Date(data.selectedAboutDate.getFullYear()-1, data.selectedAboutDate.getMonth()+12, 0).getDate();
                propery = data.selectedAboutDate ? (data.selectedAboutDate.getMonth()+2) + '' + data.selectedAboutDate.getFullYear() : null
                meals = propery ? data.data[propery][i - (selectedDay+1)] : null
            }
            else 
            if(data.selectedAboutDate.getDate() - selectedDay + i>= 1)
            {
                propery = data.selectedAboutDate ? (data.selectedAboutDate.getMonth() + 1) + '' + data.selectedAboutDate.getFullYear() : null
                meals = propery ? data.data[propery][data.selectedAboutDate.getDate() - 1 - selectedDay + i] : null
            }

            const carentDate = new Date(data.selectedAboutDate.getFullYear(),(data.selectedAboutDate.getMonth()),(data.selectedAboutDate.getDate() - selectedDay + i),0,0,0,0)

            if (meals)
                rows = [
                    createData(t('Breakfast'),meals[0].id, meals[0].meal_items, meals[0].feelings, meals[0].thoughts, meals[0].emotions, meals[0].is_binge, meals[0].delivery_at, meals[0].recipe),
                    createData(t('1stSnack'),meals[1].id, meals[1].meal_items, meals[1].feelings, meals[1].thoughts, meals[1].emotions, meals[1].is_binge, meals[1].delivery_at, meals[1].recipe),
                    createData(t('Lunch'),meals[2].id, meals[2].meal_items, meals[2].feelings, meals[2].thoughts, meals[2].emotions, meals[2].is_binge, meals[2].delivery_at, meals[2].recipe),
                    createData(t('2ndSnack'),meals[3].id, meals[3].meal_items, meals[3].feelings, meals[3].thoughts, meals[3].emotions, meals[3].is_binge, meals[3].delivery_at, meals[3].recipe),
                    createData(t('Dinner'),meals[4].id, meals[4].meal_items, meals[4].feelings, meals[4].thoughts, meals[4].emotions, meals[4].is_binge, meals[4].delivery_at, meals[4].recipe),
                    createData(t('3rdSnack'),meals[5].id, meals[5].meal_items, meals[5].feelings, meals[5].thoughts, meals[5].emotions, meals[5].is_binge, meals[5].delivery_at, meals[5].recipe)
                ];
            tableData[i]=rows;
            tableTitle[i]=formatDateLabelLong(carentDate);

            
        }
    }

    function createData(meal,id , foods, feelings, thoughts, emotions, is_binge, delivery_at, recipes) {
        let foods_names = "";
        let total_calories = 0;
        const commited = id ? true : false;
        if(foods)
            foods.forEach((item) => {
                foods_names += (item.name + ",");
                total_calories += Math.round((item.kcal / 100) * item.weight);
            });
        if(recipes)
            recipes.forEach((item) => {
                foods_names += (item.name + ",");
                total_calories += Math.round(item.eaten_kcal);
            });
        let emotions_text = "";
        emotions.forEach((item) => item.star > 0 && (emotions_text += (item.type + "(" +  item.star + "),")));

        const timeOfMeal = delivery_at.slice(11)
        
        let binge = ""
        commited && (binge = is_binge ? t('Binge') : t('NotBinge'))

        const returnObject = {
            commited,
            meal,
            foods,
            foods_names,
            feelings,
            thoughts,
            emotions_text,
            is_binge: binge,
            total_calories,
            timeOfMeal
        };
        return returnObject;
    }

    let dailyCalorieIntakeArray = [];
    let dailyCalorieIntake = 0;

    if(data.selectedAboutDate)
    {
        for(var j=0;j<7;j++)
        {
            dailyCalorieIntake = 0;
            tableData[j].forEach((row) => dailyCalorieIntake+= row.total_calories);
            dailyCalorieIntakeArray[j]=dailyCalorieIntake;
        }
    }
    useEffect(()=>{
        if(data.selectedAboutDate)
        {
            var scrollHeight=40;
            for(var i=0;i<selectedDay;i++)
            {
                var div = document.getElementById("tableId"+i);
                scrollHeight+=div.scrollHeight+20;
            }
            window.scrollTo(0, scrollHeight);
        }
    },[data.selectedAboutDate])

    const [selectIndex, setSelectIndex]= useState(-1)

    function showDailyIntake(index){
        if(openModal === true){
            if(index === selectIndex)
            {
                setOpenModal(false);
                setSelectIndex(-1)
            }
            else{
                setOpenModal(false);
                setSelectIndex(index)
                let date = new Date(tableTitle[index])
                fetchDailyIntake(selectedPerson.id, date.getDate(),date.getMonth()+1,date.getFullYear())
                setOpenModal(true);
            }
        }
        else{
            setSelectIndex(index)
            let date = new Date(tableTitle[index])
            fetchDailyIntake(selectedPerson.id, date.getDate(),date.getMonth()+1,date.getFullYear())
            setOpenModal(true);
        }
        
    }

    let sumWater=0;
    {!data.loadingDailyIntake && data.dailyIntake.water_intakes && 
        data.dailyIntake.water_intakes.forEach(item => {
            if(item.item_name === "glass")
                sumWater += 0.250;
            else
                sumWater += 0.500;
        })
    }

    function handleClose(){
        setOpenModal(false);
    }

    return (
        <>
        <div className={classes.root}>
            {
                selectedPerson != null && data.selectedAboutDate ?
                    <div style={{ padding: "4rem" }} >
                        {tableData.map((table,index)=>
                        <div id={'tableId'+index}>
                        <div className={classes.tableTitle}><div className={classes.intakeButton} onClick={(event) => showDailyIntake(index)}>{openModal && index===selectIndex ? (t('CloseDailyIntake')) : (t('OpenDailyIntake'))}<span>&#8628;</span></div><div>{tableTitle[index]}</div></div>
                        {openModal && index === selectIndex &&
                        <div className={classes.modal}>
                            {!data.loadingDailyIntake ?
                            <TableContainer className={classes.scrollProperties} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.headerRow}>
                                        <TableCell className={classes.headerRowCell} align="left">{t('DailyIntakeItem')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('DailyIntake')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={index+'1'} className={data.dailyIntake.water_intakes.length > 0 ? classes.commitedRow : classes.uncommitedRow}>
                                        <TableCell component="th" scope="row">{t('Water')}</TableCell>
                                        <TableCell align="center">{sumWater}L</TableCell>
                                    </TableRow>
                                    <TableRow key={index+'2'} className={data.dailyIntake.fruit_intakes.length > 0 ? classes.commitedRow : classes.uncommitedRow}>
                                        <TableCell component="th" scope="row">{t('Fruits')}</TableCell>
                                        <TableCell align="center">
                                        {data.dailyIntake.fruit_intakes && data.dailyIntake.fruit_intakes.length > 0 ? 
                                        <>{data.dailyIntake.fruit_intakes.map(item => {
                                            return <div>{item.item_name}</div>
                                        })}</>
                                        :
                                        <div>{t('NoFruits')}</div>
                                        }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={index+'3'} className={data.dailyIntake.vegetable_intakes.length > 0 ? classes.commitedRow : classes.uncommitedRow}>
                                        <TableCell component="th" scope="row">{t('Vegetable')}</TableCell>
                                        <TableCell align="center">
                                        {data.dailyIntake.vegetable_intakes && data.dailyIntake.vegetable_intakes.length > 0 ? 
                                        <>{data.dailyIntake.vegetable_intakes.map(item => {
                                            return <div>{item.item_name}</div>
                                        })}</>
                                        :
                                        <div>{t('NoVegetable')}</div>
                                        }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>

                            </Table>
                            </TableContainer>
                            :
                            <div className={classes.loadingDailyIntake}>{t('DataLoading')}</div>}
                        </div>
                        }
                        <TableContainer className={classes.scrollProperties} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.headerRow}>
                                        <TableCell className={classes.headerRowCell}>{t('Meals')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">Foods</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Feelings')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Thoughts')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Emotions')} (0-5)</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Binges')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Calories')}</TableCell>
                                        <TableCell className={classes.headerRowCell} align="center">{t('Time')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {table.map((row, index) => (
                                        <TableRow key={index} className={row.commited ? (row.is_binge !== 'Binge' ? classes.commitedRow : classes.bingeRow) : classes.uncommitedRow}>
                                            <TableCell component="th" scope="row">{row.meal}</TableCell>
                                            <TableCell align="center">{row.foods_names !== '' ? row.foods_names.split(',').map((line) => <div>{line}</div>) : '-'}</TableCell>
                                            <TableCell align="center">{row.feelings !== '' ? row.feelings : '-'}</TableCell>
                                            <TableCell align="center">{row.thoughts !== '' ? row.thoughts : '-'}</TableCell>
                                            <TableCell align="center">{row.emotions_text !== '' ? row.emotions_text.split(',').map((line) => <div style={{display: "flex", justifyContent: "center"}}><div style={{width: 100,textAlign: "end"}}>{t(line.slice(0,-3))}</div> <EmotionStars stars={line.slice(-2,-1)}/></div>) : '-'}</TableCell>
                                            <TableCell align="center">{row.is_binge !== '' ? row.is_binge : '-'}</TableCell>
                                            <TableCell align="center">{row.total_calories}</TableCell>
                                            <TableCell align="center">{row.timeOfMeal !== '' ? row.timeOfMeal : '-'}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow key={7} className={classes.footerRow}>
                                            <TableCell className={classes.footerRowCell} component="th" scope="row">{t('TotalCalories')}:</TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                            <TableCell className={classes.footerRowCell} align="center">{dailyCalorieIntakeArray[index]}</TableCell>
                                            <TableCell className={classes.footerRowCell} align="center"></TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                        )}
                        
                    </div>
                    :
                    <div>
                        {
                            selectedPerson ? null :
                                <>
                                    <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "127px", fontSize: "50px" }} />
                                    <Typography variant="h5" style={{ position: "absolute", top: "137px", left: width > 1250 ? "370px" : "230px" }}>
                                        {t('Tip1')}
                                    </Typography>
                                </>
                        }
                        {
                            data.selectedAboutDate ? null :
                                !data.loadingNewDates && selectedPerson &&
                                <>
                                    <KeyboardArrowLeftIcon className={width > 1250 ? "arrowright" : "arrowleft"} style={{ position: "absolute", top: "198px", fontSize: "50px" }} />
                                    <Typography variant="h5" style={{ position: "absolute", top: "208px", left: width > 1250 ? "370px" : "230px" }}>
                                        {t('Tip2')}
                                    </Typography>
                                </>

                        }


                    </div>
            }
        </div>
        
        </>
    )
}

export default withStyles(styles)(AboutPatient)
