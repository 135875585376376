import {
    DATA_LOADING,
    DATA_SUCCESS,
    DATA_FAILED,
    DATA_PERSIST,
    DATA_UPDATE_MEAL_DATE,
    DATA_UPDATE_ABOUT_DATE,
    DATA_UPDATE_STAT_DATES,
    DATA_UPDATE_CALENDAR_DATE,
    DATA_CLEAR,
    DATA_DAILY_INTAKE,
    DATA_DAILY_INTAKE_LOADING,
    DATA_DAILY_INTAKE_FINISH
} from '../actions.js';
//data={'82021': [[{},{},{},{},{},{}],[{},{},{},{},{},{}],...(31 komada)], '92021': [[{},{},{},{},{},{}],[{},{},{},{},{},{}],.....]}
const initialState = {
    data: {},
    dailyIntake: {},
    loadingDailyIntake: false,
    selectedAboutDate: null,
    selectedMealDate: null,
    selectedStatDates: [],
    currentCalendarDate: new Date(),
    loadingNewDates: false,
    message: "",
    errorMessage: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_LOADING:
            return { ...state, message: action.payload, errorMessage: "", loadingNewDates: true };

        case DATA_SUCCESS:
            return { ...state, message: action.payload.message, loadingNewDates: false };

        case DATA_FAILED:
            return { ...state, message: action.payload.message, errorMessage: action.payload.errorMessage, loadingNewDates: false }

        case DATA_PERSIST: {
            const month = action.payload.month;
            const year = action.payload.year;
            let newObject = { ...state.data }
            newObject[`${month}${year}`] = [...action.payload.data]
            return { ...state, data: newObject }
        }

        case DATA_CLEAR:
            return { ...initialState }

        case DATA_UPDATE_MEAL_DATE:
            return { ...state, selectedMealDate: action.payload }

        case DATA_UPDATE_ABOUT_DATE:
            return { ...state, selectedAboutDate: action.payload }

        case DATA_UPDATE_CALENDAR_DATE:
            return { ...state, currentCalendarDate: action.payload }

        case DATA_UPDATE_STAT_DATES:
            return { ...state, selectedStatDates: action.payload }

        case DATA_DAILY_INTAKE:
            return { ...state, dailyIntake: action.payload }
        
        case DATA_DAILY_INTAKE_LOADING:
            return { ...state, loadingDailyIntake: true }
        
        case DATA_DAILY_INTAKE_FINISH:
            return { ...state, loadingDailyIntake: false }

        default:
            return state;
    }
}