export const styles = theme => ({
    scrollProperties: {
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    iconContainer: {
        position: "fixed",
        flex: 1,
        display: 'flex',
        cursor: "pointer",
        justifyContent: 'center',
        top: "130px",
        height: "45px",
        width: "160px",
        backgroundColor: "#3c44ad",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: "2px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        zIndex: 2
    },
    iconContainer2: {
        position: "fixed",
        flex: 1,
        cursor: "pointer",
        top: "200px",
        height: "45px",
        width: "160px",
        backgroundColor: "#3c44ad",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: "2px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        zIndex: 2
    },
    iconContainer3: {
        position: "fixed",
        flex: 1,
        cursor: "pointer",
        top: "270px",
        height: "45px",
        width: "160px",
        backgroundColor: "#3c44ad",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: "2px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        zIndex: 2
    },
    noHover: {
        marginLeft: "-70px"
    },
    hover: {
        marginLeft: "38px"
    },
    icon: {
        fontSize: "35px",
        alignSelf: "flex-end",
        color: "white",
        marginLeft: '5px',
        marginRight: '10px'

    }
});